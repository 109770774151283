import React, { useContext } from 'react'
import { Helmet } from 'react-helmet';
import { Routes, Route } from "react-router-dom";
import ContactPreferences from '../Content/Account/ContactPreferences';
import ChangePassword from '../Content/Authentication/ChangePassword';
import ForgotPassword from '../Content/Authentication/ForgotPassword';
import Login from '../Content/Authentication/Login';
import SideNavigatorMobile from '../Content/Headers/SideNavigatorMobile';
import AccountSettings from '../Content/LandingPages/AccountSettings';
import AllRequirements from '../Content/LandingPages/AllRequirements';
import AllResume from '../Content/LandingPages/AllResume';
import Home from '../Content/LandingPages/Home';
import AllOpenings from '../Content/Requirements/AllOpenings';
import NewRequirements from '../Content/Requirements/NewRequirements';
import AgreementHome from '../Content/Services/AgreementHome';
import EachAgreementForSign from '../Content/Services/EachAgreementForSign';
import AllLocations from '../Content/StoresAndLocations/AllLocations';
import Subscription from '../Content/Subscription/Subscription';
import AuthContext from '../Store/auth-context';


const MainNavigator = () => {
    const authCtx = useContext(AuthContext);
    let isLoggedIn = authCtx.isLoggedIn;

    console.log(authCtx)


    if (!isLoggedIn) {
        return (
            <div>
                <Helmet>
                    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
                </Helmet>
                <Routes>
                    <Route path="*" element={<Login />}></Route>
                    <Route path="/" element={<Login />}></Route>
                    <Route path="/login" element={<Login />}></Route>
                    <Route path="/forgot-password" element={<ForgotPassword />}></Route>
                </Routes>
            </div>
        )
    } else {
        return (
            <div>
                <Helmet>
                    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
                </Helmet>
                <Routes>
                    <Route path="*" element={<Home />}></Route>
                    <Route path="/" element={<Home />}></Route>
                    <Route path="/login" element={<Home />}></Route>
                    <Route path="/menu" element={<SideNavigatorMobile />}></Route>
                    <Route path="/account-settings" element={<AccountSettings />}></Route>
                    <Route path="/resume" element={<AllResume />}></Route>
                    <Route path="/requirements" element={<AllRequirements />}></Route>
                    <Route path="/create-new-requirement/:requirementIdentifier" element={<NewRequirements />}></Route>
                    <Route path="/subscription" element={<Subscription />}></Route>
                    <Route path="/services-agreement" element={<AgreementHome />}></Route>
                    <Route path="/services-agreement/:agreementIdentifier" element={<EachAgreementForSign />}></Route>
                    <Route path="/contact-preferences" element={<ContactPreferences />}></Route>
                    <Route path="/change-password" element={<ChangePassword />}></Route>
                    <Route path="/locations" element={<AllLocations />}></Route>
                    <Route path="/jobs" element={<AllOpenings />}></Route>
                </Routes>
            </div>
        )
    }
}

export default MainNavigator