import React, { useContext, useState } from 'react'
import { CgNotes } from 'react-icons/cg'
import { FaLanguage, FaList } from 'react-icons/fa'
import { GrNotes } from 'react-icons/gr'
import { IoMdSettings } from 'react-icons/io'
import { IoDocument, IoLocationOutline } from 'react-icons/io5'
import { MdCardMembership, MdDashboardCustomize, MdLogout, MdOutlineWorkOutline, MdPeople, MdPolicy } from 'react-icons/md'
import { RiCustomerService2Line } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import ChangeLanguage from '../../Components/Settings/ChangeLanguage'
import AuthContext from '../../Store/auth-context'
import { useTranslation } from '../../Store/TranslationContext'
import styles from './SideNavigatorMobile.module.css'

const SideNavigatorMobile = () => {
    const { translations, currentLanguage, changeLanguage } = useTranslation()
    const [isClOpen, setIsClOpen] = useState(false)
    const toggleCl = () => setIsClOpen(false)
    const authCtx = useContext(AuthContext)

    return (
        <div className={styles.mainContainerMobile}>
            <ChangeLanguage isOpen={isClOpen} toggleCl={toggleCl} />
            <div className={styles.mainContent}>
                <div className={styles.mainHeader}>

                </div>
                <div className={styles.mainBody}>
                    <div className={styles.eachSectionWrapper}>
                        <div className={styles.sectionTitle}>
                            {translations?.dashboard}
                        </div>

                        <div className={styles.sectionOptionsWrapper}>
                            <Link to={`/summary`} className={styles.eachOptionWrapper}>
                                <div className={styles.optionIcon}>
                                    <MdDashboardCustomize />
                                </div>
                                <div className={styles.optionTitle}>
                                    {translations?.summary}
                                </div>
                            </Link>

                        </div>

                        <div className={styles.eachSectionWrapper}>
                            <div className={styles.sectionTitle}>
                                {translations?.services}
                            </div>
                            <div className={styles.sectionOptionsWrapper}>
                                <Link to={`/requirements`} className={styles.eachOptionWrapper}>
                                    <div className={styles.optionIcon}>
                                        <FaList />
                                    </div>
                                    <div className={styles.optionTitle}>
                                        {translations?.requirements}
                                    </div>
                                </Link>

                                <Link to={`/jobs`} className={styles.eachOptionWrapper}>
                                    <div className={styles.optionIcon}>
                                        <MdOutlineWorkOutline />
                                    </div>
                                    <div className={styles.optionTitle}>
                                        {translations?.jobs}
                                    </div>
                                </Link>

                                <Link to={`/resume`} className={styles.eachOptionWrapper}>
                                    <div className={styles.optionIcon}>
                                        <CgNotes />
                                    </div>
                                    <div className={styles.optionTitle}>
                                        {translations?.resume}
                                    </div>
                                </Link>

                                <Link to={`hired-staff`} className={styles.eachOptionWrapper}>
                                    <div className={styles.optionIcon}>
                                        <MdPeople />
                                    </div>
                                    <div className={styles.optionTitle}>
                                        {translations?.hiredStaff}
                                    </div>
                                </Link>

                                <Link to={`/services-agreement`} className={styles.eachOptionWrapper}>
                                    <div className={styles.optionIcon}>
                                        <IoDocument />
                                    </div>
                                    <div className={styles.optionTitle}>
                                        {translations?.agreement}
                                    </div>
                                </Link>
                            </div>
                        </div>

                        <div className={styles.eachSectionWrapper}>
                            <div className={styles.sectionTitle}>
                                {translations.account}
                            </div>
                            <div className={styles.sectionOptionsWrapper}>
                                <Link to={`/locations`} className={styles.eachOptionWrapper}>
                                    <div className={styles.optionIcon}>
                                        <IoLocationOutline />
                                    </div>
                                    <div className={styles.optionTitle}>
                                        {translations?.storeNCities}
                                    </div>
                                </Link>

                                <Link to={`/subscription`} className={styles.eachOptionWrapper}>
                                    <div className={styles.optionIcon}>
                                        <MdCardMembership />

                                    </div>
                                    <div className={styles.optionTitle}>
                                        {translations?.subscription}
                                    </div>
                                </Link>

                                <Link to={`/account-settings`} className={styles.eachOptionWrapper}>
                                    <div className={styles.optionIcon}>
                                        <IoMdSettings />
                                    </div>
                                    <div className={styles.optionTitle}>
                                        {translations?.settings}
                                    </div>
                                </Link>

                                <div className={styles.eachOptionWrapper}>
                                    <div className={styles.optionIcon}>
                                        <RiCustomerService2Line />
                                    </div>
                                    <div className={styles.optionTitle}>
                                        {translations?.support}
                                    </div>
                                </div>

                                <div className={styles.eachOptionWrapper}>
                                    <div className={styles.optionIcon}>
                                        <GrNotes />
                                    </div>
                                    <div className={styles.optionTitle}>
                                        {translations?.termsOfUse}
                                    </div>
                                </div>

                                <div className={styles.eachOptionWrapper}>
                                    <div className={styles.optionIcon}>
                                        <MdPolicy />
                                    </div>
                                    <div className={styles.optionTitle}>
                                        {translations?.privacyPolicy}
                                    </div>
                                </div>

                                <div className={styles.eachOptionWrapper} onClick={() => setIsClOpen(true)} >
                                    <div className={styles.optionIcon}>
                                        <FaLanguage />
                                    </div>
                                    <div className={styles.optionTitle}>
                                        {translations?.changeLanguage}
                                    </div>
                                </div>


                            </div>
                        </div>



                    </div>
                </div>
                <div className={styles.mainFooter}>
                    <button onClick={() => authCtx.logout()} className={styles.logoutBtn}>
                        <MdLogout size={25} />
                    </button>
                    <Link to={`/`} className={styles.homeBtn}>Home</Link>
                </div>
            </div>
        </div>
    )
}

export default SideNavigatorMobile