import React, { useContext, useEffect, useState } from 'react'
import OTPInput from 'react-otp-input'
import { BASE_URL_API } from '../../References/URLs/api'
import { useTranslation } from '../../Store/TranslationContext'
import styles from './ForgotPassword.module.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import AuthContext from '../../Store/auth-context'

const ForgotPassword = () => {
    const { translations, currentLanguage, changeLanguage } = useTranslation()
    const authCtx = useContext(AuthContext)
    const [otp, setOtp] = useState('');


    const [hasOtpSent, setHasOtpSent] = useState(false)

    const [timer, setTimer] = useState(20);
    const [isSendingOtp, setIsSendingOtp] = useState(false)
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [phoneNumber, setPhoneNumber] = useState('')
    const [isNumberValid, setIsNumberValid] = useState(false)
    const [isSubmittingOtp, setIsSubmittingOtp] = useState(false)
    const [sessionKey, setSessionKey] = useState('')
    const [requestCount, setRequestCount] = useState(0)


    useEffect(() => {
        let interval;

        if (isButtonDisabled) {
            interval = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer === 1) {
                        clearInterval(interval);
                        setIsButtonDisabled(false);
                        return 20; // Reset timer for next OTP send
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [isButtonDisabled]);

    const sendOtpHandler = async () => {
        // Implement OTP sending logic here
        setIsSendingOtp(true)
        const sendOtp = await fetch(BASE_URL_API + "sendOtp",
            {
                method: "POST",
                body: JSON.stringify({
                    phoneNumber: phoneNumber,
                    requestCount: requestCount,
                    sessionKey: sessionKey,
                    requestedFor: 'FORGOT_PASSWORD'
                })
            });

        if (!sendOtp.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const sendOtpRespo = await sendOtp.json()
            if (sendOtpRespo.status === "success") {
                setRequestCount(sendOtpRespo?.response?.requestCount)
                setSessionKey(sendOtpRespo?.response?.sessionKey)
                setHasOtpSent(true)
                setIsButtonDisabled(true);
                setTimer(20); // Reset timer
            } else {
                if (sendOtpRespo?.message === "number_not_registered") {
                    toast.warn(`Only registered user can request OTP.`)
                } else {

                }
            }
        }
        setIsSendingOtp(false)
    };

    const verifyOtp = async () => {
        setIsSubmittingOtp(true)
        const verifyOtpResponse = await fetch(BASE_URL_API + "verifyOtp",
            {
                method: "POST",
                body: JSON.stringify({
                    sessionKey: sessionKey,
                    phoneNumber: phoneNumber,
                    otp: otp,
                    requestedFor: 'FORGOT_PASSWORD'
                })
            });

        if (!verifyOtpResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const verifyOtpRespo = await verifyOtpResponse.json()
            if (verifyOtpRespo.status === "success") {
                authCtx.login(verifyOtpRespo?.authRespo?.token, verifyOtpRespo?.authRespo?.vendorIdentifier, verifyOtpRespo?.authRespo?.clientData)
            } else {
                if (verifyOtpRespo?.response === "failed") {
                    if (verifyOtpRespo?.message === "Otp Expired") {
                        alert("Otp Expired, please send otp again!")
                    } else {
                        alert(verifyOtpRespo?.message)
                    }
                }
            }
            console.log(verifyOtpRespo)
        }
        setIsSubmittingOtp(false)
    }


    const sanitizeInput = (input) => {
        // Remove any non-digit characters
        let sanitized = input.replace(/\D/g, '');
        // Limit the length to 10 characters
        if (sanitized.length > 10) {
            sanitized = sanitized.substring(0, 10);
        }
        setPhoneNumber(sanitized)
    }

    useEffect(() => {
        if (phoneNumber.length === 10) {
            setIsNumberValid(true)
        } else {
            setIsNumberValid(false)
        }
    }, [phoneNumber])

    const submitOtpHandler = () => {
        if (otp.length === 4) {
            verifyOtp()
        } else {
            alert("Please enter 4 digit OTP received on WhatsApp!")
        }
    }

    useEffect(() => {
        if (otp.length === 4) {
            submitOtpHandler()
        }
    }, [otp])

    const cancelHandler = () => {
        setHasOtpSent(false)
        setOtp('')
    }



    return (
        <div className={styles.mainWrapper}>
            <ToastContainer />
            <div className={styles.mainContainer}>
                <div className={styles.formWrapper}>
                    <div className={styles.forgotPasswordHeader}>{translations?.forgotPassword}</div>
                    <div className={styles.forgotPasswordBody}>
                        {hasOtpSent === false ?
                            <div className={styles.phoneNumberWrapper}>
                                <input
                                    placeholder={translations?.pleaseEnter10DigitRegisteredNumber}
                                    type="number"
                                    onChange={(e) => sanitizeInput(e.target.value)}
                                    value={phoneNumber}
                                />
                            </div>
                            :
                            <div className={styles.phoneNumberWrapper}>
                                <div className={styles.hasSentWrapper}>
                                    {translations?.haveSentOtp}
                                </div>
                                <div>

                                </div>
                            </div>
                        }

                        {hasOtpSent &&
                            <div className={styles.otpWrapper}>
                                <div className={styles.resendSectionWrapper}>
                                    <div>
                                        {translations?.havenotReceivedOTP}
                                    </div>
                                    {isButtonDisabled ?
                                        <div className={styles.resendOtpTxt}>
                                            {currentLanguage !== 'en' && timer} {translations?.resendOtpIn} {currentLanguage === 'en' && timer} {translations?.inSeconds}
                                        </div>
                                        :
                                        <div onClick={() => sendOtpHandler()} className={styles.resendOtpTxt}>
                                            {translations?.resendOtp}
                                        </div>
                                    }
                                </div>
                                <OTPInput
                                    shouldAutoFocus={true}
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={4}
                                    renderSeparator={<span>&nbsp;-&nbsp;</span>}
                                    renderInput={(props) => <input {...props} />}
                                    containerStyle={{ display: 'flex', justifyContent: "center", paddingTop: '10px', paddingBottom: '10px' }}
                                    inputStyle={{ height: '36px', width: '36px', border: '1px solid #dfdfdf', borderRadius: '5px' }}
                                    inputType="number"
                                />
                            </div>
                        }
                        {hasOtpSent !== true ?
                            <div className={styles.otpBtnWrapper}>
                                {isNumberValid === true ?
                                    isSendingOtp === false ?
                                        <button className={styles.sendOtpActiveBtn} onClick={() => sendOtpHandler()} >{translations?.sendOtp}</button>
                                        :
                                        <button className={styles.sendOtpActiveBtn} >{translations?.sendingOTP}</button>
                                    :
                                    <button className={styles.sendOtpInactiveBtn} onClick={() => alert('Please enter 10 digit phone number.')} >{translations?.sendOtp}</button>
                                }
                            </div>
                            :
                            <div className={styles.submitBtnWrapper}>
                                {isSubmittingOtp === false ?
                                    <button className={styles.submitOtpBtn} onClick={() => submitOtpHandler()} >{translations?.submitOTP}</button>
                                    :
                                    <button className={styles.submitOtpBtn} >{translations?.loggingIn}</button>
                                }

                                <div className={styles.cancelBtn} onClick={() => setHasOtpSent(false)} >{translations?.cancel}</div>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword