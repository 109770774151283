import React, { useContext, useEffect } from 'react'
import LeftNavigator from '../../Components/Headers/LeftNavigator'
import MainHeader from '../../Components/Headers/MainHeader'
import SideNavigatorMobile from '../Headers/SideNavigatorMobile'
import AuthContext from '../../Store/auth-context'
import { useTranslation } from '../../Store/TranslationContext'
import styles from './Home.module.css'

const Home = () => {
    const { translations, currentLanguage, changeLanguage } = useTranslation()
    const authCtx = useContext(AuthContext)

     
    useEffect(() => {
        const metaThemeColor = document.querySelector("meta[name='theme-color']");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", "#ECFAFF");
        } else {
            const meta = document.createElement("meta");
            meta.name = "theme-color";
            meta.content = "#ECFAFF";
            document.head.appendChild(meta);
        }
    }, []);


    return (
        <div className={styles.mainWrapper}>
            <MainHeader />
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <LeftNavigator />

                    <div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default Home