import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { BASE_URL_API } from '../../References/URLs/api'
import AuthContext from '../../Store/auth-context'
import styles from './SelectLocations.module.css'
import { IoMdRadioButtonOff, IoMdRadioButtonOn } from "react-icons/io";
import { MdBlock } from 'react-icons/md'




const SelectLocations = (props) => {
    const authCtx = useContext(AuthContext)
    const { isOpen, onClose, locationType, selectedCity, selectedStore, locationTypeHandler, selectedCityHandler, selectedStoreHandler } = props
    
    const [storesData, setStoresData] = useState([])
    const [citiesData, setCitiesData] = useState([])

    const [filteredStores, setFilteredStores] = useState([])
    const [filteredCities, setFilteredCities] = useState([])

    const [selectedStores, setSelectedStores] = useState([])
    const [selectedCities, setSelectedCities] = useState([])

    const [citySearchKey, setCitySearchKey] = useState('')
    const [storeSearchKey, setStoreSearchKey] = useState('')

    const getVendorStores = async () => {
        const getVendorStoresResponse = await fetch(BASE_URL_API + "getVendorStores",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx?.token,
                    vendorIdentifier: authCtx.userIdentifier
                })
            });

        if (!getVendorStoresResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getVendorStoresRespo = await getVendorStoresResponse.json()
            if (getVendorStoresRespo.status === "success") {
                setStoresData(getVendorStoresRespo?.response?.storesData)
                setCitiesData(getVendorStoresRespo?.response?.citiesData)
                setFilteredStores(getVendorStoresRespo?.response?.storesData)
                setFilteredCities(getVendorStoresRespo?.response?.citiesData)
            } else {
                if (getVendorStoresRespo.responseCode === 302) {
                    authCtx.logout()
                } else {

                }
            }
            console.log(getVendorStoresRespo)
        }
    }

    useEffect(() => {
        getVendorStores()
    }, [])


    useEffect(() => {
        const metaThemeColor = document.querySelector("meta[name='theme-color']");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", "#f6f6f6");
        } else {
            const meta = document.createElement("meta");
            meta.name = "theme-color";
            meta.content = "#f6f6f6";
            document.head.appendChild(meta);
        }
    }, []);


    const selectCityHandler = (k) => {
        if (selectedCity?.ID === k?.ID) {
            selectedCityHandler('')
        } else {
            selectedCityHandler(k)
        }
    }

    const selectStoreHandler = (k) => {
        if (selectedStore?.storeIdentifier === k?.storeIdentifier) {
            selectedStoreHandler('')
        } else {
            selectedStoreHandler(k)
        }
    }


    const filterDataCities = (data, searchKey) => {
        setFilteredCities(data.filter(item =>
            Object.values(item).some(value =>
                value?.toString().toLowerCase().includes(searchKey.toLowerCase())
            )
        ))
    }

    const filterDataStores = (data, searchKey) => {
        setFilteredStores(data.filter(item =>
            Object.values(item).some(value =>
                value?.toString().toLowerCase().includes(searchKey.toLowerCase())
            )
        ))
    }

    useEffect(() => {
        filterDataCities(citiesData, citySearchKey)
    }, [citySearchKey])

    useEffect(() => {
        filterDataStores(storesData, storeSearchKey)
    }, [storeSearchKey])



    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer}>
                    <div className={styles.mainContent}>
                        <div className={styles.mainHeaderWrapper}>
                            <div className={styles.locationTitle}>
                                Select Locations
                            </div>
                            <div className={styles.optionsWrapper}>
                                <button onClick={() => locationTypeHandler('Stores')} className={locationType === "Stores" ? styles.eachOptionsWrapperActive : styles.eachOptionsWrapper}>Stores</button>
                                <button onClick={() => locationTypeHandler('Cities')} className={locationType === "Cities" ? styles.eachOptionsWrapperActive : styles.eachOptionsWrapper}>Cities</button>
                            </div>
                        </div>
                        <div className={styles.mainBodyWrapper}>
                            {locationType === 'Stores' &&
                                <div className={styles.listContainer}>
                                    <div className={styles.listSearchContainer}>
                                        <input placeholder="Search for stores..."
                                            value={storeSearchKey}
                                            onChange={(e) => setStoreSearchKey(e.target.value)}
                                        />
                                    </div>
                                    <div className={styles.listItemContainer}>
                                        {filteredStores?.map((itemStores, index) => (
                                            <div key={index} className={styles.eachStoreWrapper}
                                                onClick={() => itemStores?.hasIncluded===true ? selectStoreHandler(itemStores) : alert("This store is not included in your agreement! Please contact your admin!")}
                                            >
                                                <div className={styles.checkboxIcon}>
                                                    {selectedStore?.storeIdentifier === itemStores?.storeIdentifier ?
                                                        <IoMdRadioButtonOn size={24} />
                                                        :
                                                        <IoMdRadioButtonOff size={24} />
                                                    }
                                                </div>
                                                <div className={styles.storeDetailsWrapper}>
                                                    <div>
                                                        <div className={styles.storeName}>
                                                            {itemStores?.storeName}
                                                        </div>
                                                        <div className={styles.storeAddress}>
                                                            {itemStores?.storeAddress}
                                                        </div>
                                                    </div>
                                                    <div className={styles.hasIncluded}>
                                                        {itemStores?.hasIncluded === false && <MdBlock />}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }

                            {locationType === 'Cities' &&
                                <div className={styles.listContainer}>
                                    <div className={styles.listSearchContainer}>
                                        <input placeholder="Search for cities..."
                                            value={citySearchKey}
                                            onChange={(e) => setCitySearchKey(e.target.value)}
                                        />
                                    </div>
                                    <div className={styles.listItemContainer}>
                                        {filteredCities?.map((itemCities, index) => (
                                            <div key={index} className={styles.eachCityWrapper}
                                                onClick={() => itemCities?.hasIncluded === true ? selectCityHandler(itemCities) : alert('This city is not included in your agreement. Kindly contact your RM.')}
                                            >
                                                <div className={styles.checkboxIcon}>
                                                    {selectedCity?.ID === itemCities?.ID ?
                                                        <IoMdRadioButtonOn size={24} />
                                                        :
                                                        <IoMdRadioButtonOff size={24} color={itemCities?.hasIncluded===true ? `#000000` : `#696969`} />
                                                    }
                                                </div>
                                                <div className={itemCities?.hasIncluded === true ? styles.cityDetailWrapper : styles.cityDetailWrapperDull}>
                                                    <div className={styles.cityIcon}>
                                                        {itemCities?.city}
                                                    </div>
                                                    <div className={styles.hasIncluded}>
                                                        {itemCities?.hasIncluded === false && <MdBlock />}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }

                        </div>
                        <div className={styles.mainFooterWrapper}>
                            <button className={styles.closeBtn} onClick={() => onClose()} >Close</button>
                            <button className={styles.updateBtn} onClick={() => onClose()} >Update</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default SelectLocations