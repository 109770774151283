import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import LeftNavigator from '../../Components/Headers/LeftNavigator'
import MainHeader from '../../Components/Headers/MainHeader'
import EachResumeRow from '../../Components/LandingPages/EachResumeRow'
import styles from './AllRequirements.module.css'
import { FaFilter, FaPlus } from "react-icons/fa";
import NewRequirementPopUp from '../Requirements/NewRequirementPopUp'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { BASE_URL_API } from '../../References/URLs/api'
import AuthContext from '../../Store/auth-context'
import { humanReadableTimePassed } from '../../Helper/jarvis-jr'


const AllRequirements = () => {
    const authCtx = useContext(AuthContext)
    const [allRequirements, setAllRequirements] = useState([])
    const [allRequirementsFiltered, setAllRequirementsFiltered] = useState([])
    const [isNewRequirementPopupOpen, setIsNewRequirementPopupOpen] = useState(false)
    const [searchKey, setSearchKey] = useState('')
    const newRequirementPopupCloseHandler = () => setIsNewRequirementPopupOpen(false)

    const getAllRequirements = async () => {
        const getAllRequirementsResponse = await fetch(BASE_URL_API + "getAllRequirements",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    vendorIdentifier: authCtx?.userIdentifier
                })
            });

        if (!getAllRequirementsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllRequirementsRespo = await getAllRequirementsResponse.json()
            if (getAllRequirementsRespo.status === "success") {
                setAllRequirements(getAllRequirementsRespo?.response)
                setAllRequirementsFiltered(getAllRequirementsRespo?.response)
            } else {
                if (getAllRequirementsRespo.responseCode === 302) {
                    authCtx.logout()
                } else {
                    setAllRequirements([])
                }
            }
            console.log(getAllRequirementsRespo)
        }
    }

    useEffect(() => {
        const metaThemeColor = document.querySelector("meta[name='theme-color']");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", "#ECFAFF");
        } else {
            const meta = document.createElement("meta");
            meta.name = "theme-color";
            meta.content = "#ECFAFF";
            document.head.appendChild(meta);
        }
    }, []);

    useEffect(() => {
        getAllRequirements()

    }, [])

    const filterData = (data, searchKey) => {
        setAllRequirementsFiltered(data.filter(item =>
            Object.values(item).some(value =>
                value?.toString().toLowerCase().includes(searchKey.toLowerCase())
            )
        ))
    }

    useEffect(() => {
        filterData(allRequirements, searchKey)
    }, [searchKey])
    


    return (
        <div className={styles.mainWrapper}>
            <MainHeader />
            <ToastContainer />
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <LeftNavigator />
                    {isNewRequirementPopupOpen === true && <NewRequirementPopUp isOpen={isNewRequirementPopupOpen} onClose={newRequirementPopupCloseHandler} />}
                    <div className={styles.rightContentWrapper}>
                        <div className={styles.resumeTableWrapper}>
                            <div className={styles.filtersWrapper}>
                                <div className={styles.leftFiltersWrapper}>
                                    <input placeholder="Search for anything..." 
                                        onChange={(e) => setSearchKey(e.target.value)}
                                        value={searchKey}
                                    />
                                </div>
                                <div className={styles.tableActionWrapper}>
                                    <button>
                                        <FaFilter />
                                    </button>
                                    {/* <Link to={`/create-new-requirement`} title="Create A New Requirement">
                                        <FaPlus />
                                    </Link> */}
                                    <button onClick={() => setIsNewRequirementPopupOpen(true)}>
                                        <FaPlus />
                                    </button>
                                </div>
                            </div>
                            <div className={styles.headerWrapper}>
                                <div className={styles.sn}>#</div>
                                <div className={styles.candidateName}>Profile</div>
                                <div className={styles.location}>Location</div>
                                <div className={styles.rightAction}>Action</div>
                            </div>
                            <div className={styles.headerWrapperPhone}>
                                Requirements are available here..
                            </div>
                            <div className={styles.tableBodyWrapper}>
                                {allRequirementsFiltered?.map((item, index) => (
                                    <div key={index} className={styles.eachRowWrapper}>
                                        <div className={styles.sn}>
                                            {index + 1}.
                                        </div>
                                        <div className={styles.candidateName}>
                                            {item?.profileLabel} - ({item?.numOfRequirements})
                                        </div>
                                        <div className={styles.location}>
                                            {item?.locationType === `Stores` &&
                                                <div>
                                                    <div className={styles.storeName}>
                                                        {item?.storeName}
                                                    </div>
                                                    <div className={styles.storeAddress}>
                                                        {item?.storeAddress}
                                                    </div>
                                                </div>
                                            }
                                            {item?.locationType === `Cities` &&
                                                <div>
                                                    <div className={styles.cityName}>
                                                        {item?.cityName}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className={styles.rightAction}>
                                            <div className={styles.timeago}>
                                                Requested {humanReadableTimePassed(item?.submittedOn)}
                                            </div>
                                            <div className={styles.viewDetails}>
                                                <Link to={`/create-new-requirement/${item?.requirementIdentifier}`}>View Details</Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllRequirements