import React from 'react'
import LeftNavigator from '../../Components/Headers/LeftNavigator'
import MainHeader from '../../Components/Headers/MainHeader'
import styles from './AccountSettings.module.css'
import { MdOutlineContactPhone } from "react-icons/md";
import { Link } from 'react-router-dom';
import { RiLockPasswordFill } from "react-icons/ri";




const AccountSettings = () => {
    return (
        <div className={styles.mainWrapper}>
            <MainHeader />
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <LeftNavigator />
                    <div className={styles.rightContentWrapper}>
                        <div className={styles.settingOptionsWrapper}>

                            <Link to="/contact-preferences" className={styles.eachSettingWrapper}>
                                <div>
                                    <div>
                                        <MdOutlineContactPhone size={30} />
                                    </div>
                                    <div className={styles.eswTxt}>
                                        Contact Preferences
                                    </div>
                                </div>
                            </Link>
                            <Link to="/change-password" className={styles.eachSettingWrapper}>
                                <div>
                                    <div>
                                        <RiLockPasswordFill size={30} />
                                    </div>
                                    <div className={styles.eswTxt}>
                                        Change Password
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default AccountSettings