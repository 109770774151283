import React, { useContext, useEffect, useState } from 'react'
import MainHeader from '../../Components/Headers/MainHeader'
import { useTranslation } from '../../Store/TranslationContext'
import styles from './NewRequirements.module.css'

import { MdKeyboardBackspace } from "react-icons/md";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FaMinus, FaPlus } from 'react-icons/fa';
import LoaderFullPage from '../../Components/Loaders/LoaderFullPage';
import { Helmet } from 'react-helmet';
import SelectLocations from '../../Components/Requirements/SelectLocations';
import { BASE_URL_API } from '../../References/URLs/api';
import AuthContext from '../../Store/auth-context';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';


const NewRequirements = () => {
    const { translations, currentLanguage, changeLanguage } = useTranslation()
    const navigate = useNavigate()
    const authCtx = useContext(AuthContext)
    const { requirementIdentifier } = useParams()


    const profilesData =
        [
            {
                "ID": "1",
                "category_name": "Beautician",
                title: translations?.beautician,
                "isActive": "1",
                "addedOn": "2022-02-03 14:30:09"
            },
            {
                "ID": "15",
                "category_name": "Beauty Advisor / Consultant",
                title: translations?.beautyAdvisor,
                "isActive": "1",
                "addedOn": "2022-11-09 17:30:09"
            },
            {
                "ID": "13",
                "category_name": "Beauty Trainer",
                title: translations?.beautyTrainer,
                "isActive": "1",
                "addedOn": "2022-11-09 17:30:09"
            },
            {
                "ID": "19",
                "category_name": "Eyelash Trainer",
                title: translations?.eyelashTrainer,
                "isActive": "1",
                "addedOn": "2022-11-30 17:00:09"
            },
            {
                "ID": "20",
                "category_name": "Female Hairdresser / Hairstylist",
                title: translations?.femaleHairdresser,
                "isActive": "1",
                "addedOn": "2023-01-06 12:04:09"
            },
            {
                "ID": "17",
                "category_name": "Gents Hairdresser / Hairstylist",
                title: translations?.gentsHairdresser,
                "isActive": "1",
                "addedOn": "2022-02-03 14:30:09"
            },
            {
                "ID": "14",
                "category_name": "Hair Trainer",
                title: translations?.hairTrainer,
                "isActive": "1",
                "addedOn": "2022-11-09 17:30:09"
            },
            {
                "ID": "9",
                "category_name": "Helper",
                title: translations?.helper,
                "isActive": "1",
                "addedOn": "2022-02-03 14:30:09"
            },
            {
                "ID": "12",
                "category_name": "Make-up Trainer",
                title: translations?.makeUpTrainer,
                "isActive": "1",
                "addedOn": "2022-11-09 17:30:09"
            },
            {
                "ID": "2",
                "category_name": "Makeup Artist",
                title: translations?.makeupArtist,
                "isActive": "1",
                "addedOn": "2022-02-03 14:30:09"
            },
            {
                "ID": "11",
                "category_name": "Manager",
                title: translations?.manager,
                "isActive": "1",
                "addedOn": "2022-11-08 17:30:09"
            },
            {
                "ID": "4",
                "category_name": "Manicurist",
                title: translations?.manicurist,
                "isActive": "1",
                "addedOn": "2022-02-03 14:30:09"
            },
            {
                "ID": "16",
                "category_name": "Massage Therapist",
                title: translations?.massageTherapist,
                "isActive": "1",
                "addedOn": "2022-11-11 17:30:09"
            },
            {
                "ID": "21",
                "category_name": "Mehandi Artist",
                title: translations?.mehandiArtist,
                "isActive": "1",
                "addedOn": "2023-11-28 12:04:09"
            },
            {
                "ID": "6",
                "category_name": "Nail Art / Technician",
                title: translations?.nailArtTechnician,
                "isActive": "1",
                "addedOn": "2022-02-03 14:30:09"
            },
            {
                "ID": "18",
                "category_name": "Nail Art Trainer",
                title: translations?.nailArtTrainer,
                "isActive": "1",
                "addedOn": "2022-11-30 17:00:09"
            },
            {
                "ID": "5",
                "category_name": "Pedicurist",
                title: translations?.pedicurist,
                "isActive": "1",
                "addedOn": "2022-02-03 14:30:09"
            },
            {
                "ID": "10",
                "category_name": "Receptionist",
                title: translations?.receptionist,
                "isActive": "1",
                "addedOn": "2022-11-08 17:30:09"
            },
            {
                "ID": "3",
                "category_name": "Unisex Hairdresser / Hairstylist",
                title: translations?.unisexHairdresser,
                "isActive": "1",
                "addedOn": "2022-02-03 14:30:09"
            }
        ]

    const [filteredProfileData, setFilteredProfileData] = useState([])
    const [searchKey, setSearchKey] = useState('')
    const [openQuant, setOpenQuant] = useState(1)
    const [requirementTimeline, setRequirementTimeline] = useState('')
    const [addingNewRequirements, setAddingNewRequirements] = useState(false)
    const [isSelectLocationOpen, setIsSelectLocationOpen] = useState(false)
    const toggleLocationSelector = () => setIsSelectLocationOpen(false)

    const searchProfiles = () => {
        if (!searchKey.trim()) {
            setFilteredProfileData(profilesData)
        }

        setFilteredProfileData(
            profilesData.filter(profile => {
                return Object.values(profile).some(value =>
                    value && value.toString().toLowerCase().includes(searchKey.toLowerCase())
                );
            })
        )
    }
    const [selectedProfile, setSelectedProfile] = useState()

    const [locationType, setLocationType] = useState('Stores')
    const [selectedCity, setSelectedCity] = useState(null)
    const [selectedStore, setSelectedStore] = useState(null)

    const locationTypeHandler = (k) => {
        setLocationType(k)
    }
    const selectedCityHandler = (k) => {
        setSelectedCity(k)
    }
    const selectedStoreHandler = (k) => {
        setSelectedStore(k)
    }


    useEffect(() => {
        setFilteredProfileData(profilesData)
    }, [])

    useEffect(() => {
        searchProfiles()
    }, [searchKey])

    const openQuantHandler = (k) => {
        let temp = openQuant;
        if (k === '+') {
            if (temp < 50) {
                temp++
            }
        } else if (k === "-") {
            if (temp > 1) {
                temp--
            }
        }
        setOpenQuant(temp)
    }

    const createRequirement = async () => {
        setAddingNewRequirements(true)
        // navigate("/requirements")
        // console.log(requirementIdentifier, openQuant, requirementTimeline, selectedProfile?.category_name, selectedProfile?.ID, locationType, selectedCity?.ID, selectedCity?.city, selectedStore?.storeIdentifier, selectedStore?.storeName,  selectedStore?.storeAddress)
        const updateNewRequirementResponse = await fetch(BASE_URL_API + "updateNewRequirement",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    requirementIdentifier: requirementIdentifier,
                    numOfRequirements: openQuant,
                    urgencyLevel: requirementTimeline,
                    profileLabel: selectedProfile?.category_name,
                    profileIdentifier: selectedProfile?.ID,
                    locationType: locationType,
                    cityIdentifier: selectedCity?.ID,
                    cityName: selectedCity?.city,
                    storeIdentifier: selectedStore?.storeIdentifier,
                    storeName: selectedStore?.storeName,
                    storeAddress: selectedStore?.storeAddress
                })
            });

        if (!updateNewRequirementResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateNewRequirementRespo = await updateNewRequirementResponse.json()
            if (updateNewRequirementRespo.status === "success") {
                toast.success("Requirement successfully created!")
                setTimeout(() => {
                    navigate("/requirements")
                }, 2000);
            } else {
                if (updateNewRequirementRespo.responseCode === 302) {
                    authCtx.logout()
                } else {

                }
            }
            console.log(updateNewRequirementRespo)
        }
        setAddingNewRequirements(false)
    }

    useEffect(() => {
        const metaThemeColor = document.querySelector("meta[name='theme-color']");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", "#dfdfdf30");
        } else {
            const meta = document.createElement("meta");
            meta.name = "theme-color";
            meta.content = "#dfdfdf30";
            document.head.appendChild(meta);
        }
    }, []);

    


    return (
        <div className={styles.mainWrapper}>
            {addingNewRequirements && <LoaderFullPage />}
            <MainHeader />
            <ToastContainer />
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.mainHeaderWrapper}>
                        <div className={styles.searchWrapper}>
                            <div className={styles.backBtnWrapper}>
                                <Link to={`/requirements`} className={styles.backBtn}>
                                    <MdKeyboardBackspace size={26} />
                                </Link>
                            </div>
                            <div className={styles.searchBarWrapper}>
                                <input placeholder={translations?.searchForAProfile}
                                    onChange={(e) => setSearchKey(e.target.value)} autoFocus
                                    onFocus={() => setSelectedProfile('')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={styles.mainBodyWrapper}>
                        {selectedProfile === '' ?
                            <div className={styles.profilesWrapper}>
                                {filteredProfileData?.map((item, index) => (
                                    <div key={index} className={styles.eachProfile} onClick={() => setSelectedProfile(item)} >
                                        <div>
                                            {item?.title}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            :
                            <div className={styles.profileSelectedWrapper}>
                                <div className={styles.groupedData}>
                                    <div className={styles.twoInARowWrapper}>
                                        <div className={styles.selectedProfile} onClick={() => setSelectedProfile('')}>
                                            {selectedProfile?.title}
                                        </div>
                                        <div className={styles.staffQuantWrapper}>
                                            <div className={styles.quantQuestion}>
                                                {translations?.howMany} {selectedProfile?.title} {translations?.doYouNeed}
                                            </div>
                                            <div className={styles.quantWrapper}>
                                                <div onClick={() => openQuantHandler('-')} className={styles.quantControlBtn} >
                                                    <FaMinus />
                                                </div>
                                                <div className={styles.quant}>
                                                    {openQuant}
                                                </div>
                                                <div onClick={() => openQuantHandler('+')} className={styles.quantControlBtn} >
                                                    <FaPlus />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {isSelectLocationOpen && <SelectLocations
                                        isOpen={isSelectLocationOpen}
                                        onClose={toggleLocationSelector}
                                        requirementIdentifier={requirementIdentifier}
                                        locationType={locationType}
                                        selectedCity={selectedCity}
                                        selectedStore={selectedStore}
                                        locationTypeHandler={locationTypeHandler}
                                        selectedCityHandler={selectedCityHandler}
                                        selectedStoreHandler={selectedStoreHandler}
                                    />}
                                    <div className={styles.locationWrapper}>
                                        <div className={styles.locationTitle}>
                                        Selected {locationType}
                                        </div>
                                        {locationType === 'Cities' &&
                                            <div className={styles.selectedLocation}>
                                                {selectedCity?.city}
                                            </div>
                                        }
                                        {locationType === 'Stores' &&
                                            <div className={styles.selectedLocation}>
                                                <div className={styles.storeName}>
                                                    {selectedStore?.storeName}
                                                </div>
                                                <div className={styles.storeAddress}>
                                                    {selectedStore?.storeAddress}
                                                </div>
                                            </div>
                                        }
                                        <button className={styles.changeLocationBtn} onClick={() => setIsSelectLocationOpen(true)} >Change Locations</button>
                                    </div>

                                    <div className={styles.timelineWrapper}>
                                        <div className={styles.timelineQuestion}>
                                            {translations?.whenDoYouNeedStaff}
                                        </div>
                                        <div className={styles.timelineOptionsWrapper}>
                                            <div className={requirementTimeline === "Immediately" ? styles.eachTimelineActive : styles.eachTimeline}
                                                onClick={() => setRequirementTimeline('Immediately')}
                                            >
                                                {translations?.immediately}
                                            </div>
                                            <div className={requirementTimeline === "Within a week" ? styles.eachTimelineActive : styles.eachTimeline}
                                                onClick={() => setRequirementTimeline('Within a week')}
                                            >
                                                {translations?.withinAWeek}
                                            </div>
                                            <div className={requirementTimeline === "Within 15 days" ? styles.eachTimelineActive : styles.eachTimeline}
                                                onClick={() => setRequirementTimeline('Within 15 days')}
                                            >
                                                {translations?.within15Days}
                                            </div>
                                            <div className={requirementTimeline === "Next month" ? styles.eachTimelineActive : styles.eachTimeline}
                                                onClick={() => setRequirementTimeline('Next month')}
                                            >
                                                {translations?.nextMonth}
                                            </div>
                                        </div>
                                    </div>



                                </div>

                                <div className={styles.submitBtnWrapper}>
                                    <div className={styles.submitBtnContainer}>
                                        <button
                                            onClick={() => createRequirement()}
                                            className={styles.submitBtn}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>

                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewRequirements