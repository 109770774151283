import React, { useContext, useEffect, useState } from 'react'
import LeftNavigator from '../../Components/Headers/LeftNavigator'
import MainHeader from '../../Components/Headers/MainHeader'
import styles from './ChangePassword.module.css'

import { SiTicktick } from "react-icons/si";
import { BASE_URL_API } from '../../References/URLs/api';
import AuthContext from '../../Store/auth-context';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const ChangePassword = () => {
    const authCtx = useContext(AuthContext)
    const [readyToUpdate, setReadyToUpdate] = useState(false)
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [reEnteredPassword, setReEnteredPassword] = useState('')
    const [isUpdatingPassword, setIsUpdatingPassword] = useState(false)

    useEffect(() => {
        if (currentPassword.length > 3) {
            if (newPassword.length > 3) {
                if (newPassword === reEnteredPassword) {
                    setReadyToUpdate(true)
                } else {
                    setReadyToUpdate(false)
                }
            } else {
                setReadyToUpdate(false)
            }
        } else {
            setReadyToUpdate(false)
        }
    }, [currentPassword, newPassword, reEnteredPassword])


    const validatePassword = (password) => {
        // Regular expression to match the password requirements
        const regex = /^[A-Za-z0-9\$#\*@%_\-]+$/;

        // Check if the password meets the minimum length requirement
        if (password.length < 5) {
            return { status: false, reason: 'password must be at least 5 characters long' };
        }

        // Check if the password matches the regular expression
        if (!regex.test(password)) {
            return { status: false, reason: 'invalid characters found' };
        }

        return { status: true, reason: null };
    };

    const updatePassword = async () => {
        setIsUpdatingPassword(true)
        const updatePasswordResponse = await fetch(BASE_URL_API + "updatePassword",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx?.token,
                    vendorIdentifier: authCtx?.userIdentifier,
                    currentPassword: currentPassword,
                    newPassword: newPassword,
                    reEnteredNewPassword: reEnteredPassword
                })
            });

        if (!updatePasswordResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updatePasswordRespo = await updatePasswordResponse.json()
            if (updatePasswordRespo.status === "success") {
                toast.success("Successfully updated the password!")
                setCurrentPassword('')
                setNewPassword('')
                setReEnteredPassword('')
            } else {
                if (updatePasswordRespo.responseCode === 302) {
                    authCtx.logout()

                } else {
                    toast.warn(updatePasswordRespo?.message)
                }
            }
            console.log(updatePasswordRespo)
        }
        setIsUpdatingPassword(false)
    }


    return (
        <div className={styles.mainWrapper}>
            <MainHeader />
            <ToastContainer />
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <LeftNavigator />
                    <div className={styles.rightContentWrapper}>
                        <div className={styles.changePasswordWrapper}>
                            <div className={styles.changePasswordContainer}>

                                <div className={styles.eachPasswordFieldWrapper}>
                                    <div className={styles.epfwTxtWrapper}>
                                        <div className={styles.epfwTitleWrapper}>
                                            <div className={styles.epfwTitle}>
                                                Enter your current password
                                            </div>
                                            {validatePassword(currentPassword)?.status === true ?
                                                <div>
                                                    <SiTicktick size={16} color={`green`} />
                                                </div>
                                                :
                                                <div>
                                                    <SiTicktick size={16} color={`#dfdfdf`} />
                                                </div>
                                            }
                                        </div>
                                        <div className={styles.reason}>
                                            {validatePassword(currentPassword)?.reason}
                                        </div>
                                    </div>
                                    <div className={styles.epfwInput}>
                                        <input value={currentPassword} placeholder="Please enter the password" type="password" onChange={(e) => setCurrentPassword(e.target.value)} />
                                    </div>
                                </div>
                                <div className={styles.eachPasswordFieldWrapper}>
                                    <div className={styles.epfwTxtWrapper}>
                                        <div className={styles.epfwTitleWrapper}>
                                            <div className={styles.epfwTitle}>Enter new password</div>
                                            {validatePassword(newPassword)?.status === true ?
                                                <div>
                                                    <SiTicktick size={16} color={`green`} />
                                                </div>
                                                :
                                                <div>
                                                    <SiTicktick size={16} color={`#dfdfdf`} />
                                                </div>
                                            }
                                        </div>
                                        <div className={styles.reason}>
                                            {validatePassword(newPassword)?.reason}
                                        </div>
                                    </div>
                                    <div className={styles.epfwInput}>
                                        <input value={newPassword} placeholder="Please enter the password" type="password" onChange={(e) => setNewPassword(e.target.value)} />
                                    </div>
                                </div>
                                <div className={styles.eachPasswordFieldWrapper}>
                                    <div className={styles.epfwTxtWrapper}>
                                        <div className={styles.epfwTitleWrapper}>
                                            <div className={styles.epfwTitle}>
                                                Re-enter new password
                                            </div>
                                            {newPassword === reEnteredPassword ?
                                                <div>
                                                    <SiTicktick size={16} color={`green`} />
                                                </div>
                                                :
                                                <div>
                                                    <SiTicktick size={16} color={`#dfdfdf`} />
                                                </div>
                                            }
                                        </div>
                                        <div className={styles.reason}>
                                            {newPassword !== reEnteredPassword && `Password should match the newly entered password.`}
                                        </div>
                                    </div>
                                    <div className={styles.epfwInput}>
                                        <input value={reEnteredPassword} placeholder="Please enter the password" type="password" onChange={(e) => setReEnteredPassword(e.target.value)} />
                                    </div>
                                </div>

                                <div className={styles.actionWrapper}>
                                    {readyToUpdate === true ?
                                        isUpdatingPassword === true ?
                                            <button className={styles.updatePasswordActive} >Updating Password...</button>
                                            :
                                            <button className={styles.updatePasswordActive} onClick={() => updatePassword()} >Update Password</button>
                                        :
                                        <button className={styles.updatePasswordInActive} >Update Password</button>
                                    }
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword