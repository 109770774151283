import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import LeftNavigator from '../../Components/Headers/LeftNavigator'
import MainHeader from '../../Components/Headers/MainHeader'
import SPBusiness from '../../Components/SubscribedPlans/SPBusiness'
import SPEssential from '../../Components/SubscribedPlans/SPEssential'
import SPPlus from '../../Components/SubscribedPlans/SPPlus'
import { humanReadableTimePassed } from '../../Helper/jarvis-jr'
import { BASE_URL_API } from '../../References/URLs/api'
import AuthContext from '../../Store/auth-context'
import styles from './Subscription.module.css'


const Subscription = () => {
    const [subscriptionData, setSubscriptionData] = useState([1, 23, 4])
    const authCtx = useContext(AuthContext)
    const [activeTab, setActiveTab] = useState('All')

    const getAllSubscriptions = async () => {
        const getAllSubscriptionsResponse = await fetch(BASE_URL_API + "getAllSubscriptions",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    userIdentifier: authCtx.userIdentifier
                })
            });

        if (!getAllSubscriptionsResponse.ok) {
            console.log(`Something went wrong : Server Error`)
        } else {
            const getAllSubscriptionsRespo = await getAllSubscriptionsResponse.json()
            if (getAllSubscriptionsRespo.status === "success") {
                setSubscriptionData(getAllSubscriptionsRespo.response)
            } else {
                if (getAllSubscriptionsRespo.responseCode === 302) {
                    authCtx.logout()
                } else if (getAllSubscriptionsRespo?.message === "no records found!") {
                    setSubscriptionData(null)
                }
            }
            console.log(getAllSubscriptionsRespo)
        }
    }

    useEffect(() => {
        getAllSubscriptions()
    }, [])


    useEffect(() => {
        const metaThemeColor = document.querySelector("meta[name='theme-color']");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", "#B8EDFF");
        } else {
            const meta = document.createElement("meta");
            meta.name = "theme-color";
            meta.content = "#B8EDFF";
            document.head.appendChild(meta);
        }
    }, []);



    return (
        <div className={styles.mainWrapper}>
            <MainHeader />
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <LeftNavigator />
                    <div className={styles.rightContentWrapper}>
                        {subscriptionData === null ?
                            <div className={styles.noSubscribed}>
                                <div className={styles.missingOutTxt}>
                                    You are missing out of the super power!
                                </div>
                                <div className={styles.betterWayTxt}>
                                    <p>Stylelink is a better way to hire.</p>
                                    <p>We're an AI-powered platform that sources, vets, and pays your next expert.</p>
                                </div>
                                <div>
                                    Our exclusive plans are custom tailored for your business.
                                </div>
                                <div className={styles.explorePlansWrapper}>
                                    <a href="https://stylelink.in/hire-salon-staff/pricing" target="_blank" >Explore Plans</a>
                                </div>
                            </div>
                            :
                            <div className={styles.subscriptionWrapper}>
                                <div className={styles.tabWrapper}>
                                    <div className={activeTab === "All" ? styles.eachTabWrapperActive : styles.eachTabWrapper}
                                        onClick={() => setActiveTab("All")}
                                    >
                                        All
                                    </div>
                                    <div className={activeTab === "Active" ? styles.eachTabWrapperActive : styles.eachTabWrapper}
                                        onClick={() => setActiveTab("Active")}
                                    >
                                        Active
                                    </div>
                                    <div className={activeTab === "Expired" ? styles.eachTabWrapperActive : styles.eachTabWrapper}
                                        onClick={() => setActiveTab("Expired")}
                                    >
                                        Expired
                                    </div>
                                </div>

                                <div className={styles.subscriptionList}>
                                    <div className={styles.subscriptionsCount}>
                                        {subscriptionData?.length} subscription(s) found!
                                    </div>
                                    {subscriptionData?.map((item, index) => (
                                        <Link to={`/subscription/${item?.agreementIdentifier}`} className={styles.eachPlanWrapper} key={index}>
                                            <div className={styles.titleStatusWrapper}>
                                                <div className={styles.selectedPlan}>
                                                    {item?.selectedPlan}
                                                </div>
                                                <div>
                                                    {item?.contractStatus === 'Active' ?
                                                        <div className={styles.activeTag}>
                                                            Active
                                                        </div>
                                                        :
                                                        <div className={styles.expiredTag}>
                                                            Expired
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className={styles.tagsWrapper}>
                                                <div className={styles.subscriptionTerms}>
                                                    Subscription Fee Terms : {item?.subscriptionFeeTerms}
                                                </div>
                                            </div>
                                            <div>
                                                {item?.contractStatus === 'Active' ?
                                                    <div className={styles.subscriptionTerms}>
                                                        Valid Till : {item?.contractValidTill?.substr(0, 10)}
                                                    </div>
                                                    :
                                                    <div className={styles.subscriptionTerms}>
                                                        Expired {humanReadableTimePassed(item?.contractValidTill)}
                                                    </div>
                                                }
                                            </div>
                                        </Link>
                                    ))}
                                    <div className={styles.thatsAll}>
                                        That's all!
                                    </div>
                                </div>
                                {subscriptionData === "Plus" && <SPPlus />}
                                {subscriptionData === "Essential" && <SPEssential />}
                                {subscriptionData === "Business" && <SPBusiness />}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Subscription