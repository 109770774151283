import React from 'react'
import styles from './EachResumeRow.module.css'

const EachResumeRow = (props) => {
    const { item, viewResumeHandler, count } = props
    return (
        <div className={styles.rowWrapper}>
            <div className={styles.sn}>{count+1}.</div>
            <div className={styles.candidateName}>Navneet Chandan</div>
            <div className={styles.phoneNumber}>+91-9999648406</div>
            <div className={styles.age}>24</div>
            <div className={styles.profile}>Unisex Hair dresser</div>
            <div className={styles.gender}>Male</div>
            <div className={styles.action}>
                <a href="" target="_blank" >
                    View Resume
                </a>
            </div>
        </div>
    )
}

export default EachResumeRow