import React from 'react'
import { useTranslation } from '../../Store/TranslationContext'
import styles from './ChangeLanguage.module.css'
import { IoCheckmarkDone } from "react-icons/io5";


const ChangeLanguage = (props) => {
    const { isOpen, toggleCl } = props
    const { translations, currentLanguage, changeLanguage } = useTranslation()

    const toggleLanguageChanger = async (k) => {
        changeLanguage(k)
        toggleCl()
    }


    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer}>
                    <div className={styles.mainContent}>
                        <div className={styles.mainHeader}>
                            {translations?.changeLanguage}
                        </div>
                        <div className={styles.mainBody}>
                            <div className={styles.eachLanguageWrapper} onClick={() => toggleLanguageChanger('en')} >
                                <div className={styles.eachLangTxt}>
                                    English
                                </div>
                                <div className={styles.eachLangIcon}>
                                    {currentLanguage==="en" &&  <IoCheckmarkDone />}
                                </div>
                            </div>
                            <div className={styles.eachLanguageWrapper} onClick={() => toggleLanguageChanger('hi')} >
                                <div className={styles.eachLangTxt}>
                                    हिंदी
                                </div>
                                <div className={styles.eachLangIcon}>
                                    {currentLanguage==="hi" &&  <IoCheckmarkDone />}
                                </div>
                            </div>
                            <div className={styles.eachLanguageWrapper} onClick={() => toggleLanguageChanger('hinglish')} >
                                <div className={styles.eachLangTxt}>
                                    Hinglish
                                </div>
                                <div className={styles.eachLangIcon}>
                                    {currentLanguage==="hinglish" &&  <IoCheckmarkDone />}
                                </div>
                            </div>
                        </div>
                        <div className={styles.mainFooter}>
                            <button onClick={() => toggleCl()} className={styles.closeBtn} >{translations?.close}</button>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default ChangeLanguage