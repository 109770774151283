import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from '../../Store/TranslationContext'
import styles from './Login.module.css'
import OtpInput from 'react-otp-input';
import { isValidPhoneNumber } from '../../Helper/jarvis-jr';
import AuthContext from '../../Store/auth-context';
import { Helmet } from 'react-helmet';
import { BASE_URL_API } from '../../References/URLs/api';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';



const Login = () => {
    const { translations, currentLanguage, changeLanguage } = useTranslation()
    const authCtx = useContext(AuthContext)

    const [username, setUsername] = useState('')
    const [hasUsernameValidated, setHasUsernameValidated] = useState(false)
    const [password, setPassword] = useState('')
    const [otp, setOtp] = useState('');
    const [canSendOtp, setCanSendOtp] = useState(false)
    const [hasOtpSent, setHasOtpSent] = useState(false)
    const [isSendingOtp, setIsSendingOtp] = useState(false)
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)
    const [isLogging, setIsLogging] = useState(false)

    const [timer, setTimer] = useState(15);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const [sessionKey, setSessionKey] = useState('')
    const [requestCount, setRequestCount] = useState(0)

    const changeUsernameHandler = () => {
        setHasUsernameValidated(false)
        setHasOtpSent(false)
        setCanSendOtp(false)
    }

    const validateUsernameHandler = async () => {
        if (username !== '') {
            if (isValidPhoneNumber(username)) {
                setCanSendOtp(true)
            } else {
                setCanSendOtp(false)
            }
            setHasUsernameValidated(true)
        } else {
            alert("Please enter a valid username")
        }
    }


    useEffect(() => {
        let interval;

        if (isButtonDisabled) {
            interval = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer === 1) {
                        clearInterval(interval);
                        setIsButtonDisabled(false);
                        return 15; // Reset timer for next OTP send
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [isButtonDisabled]);

    const sendOtpHandler = async () => {
        // Implement OTP sending logic here
        setIsSendingOtp(true)
        const sendOtp = await fetch(BASE_URL_API + "sendOtp",
            {
                method: "POST",
                body: JSON.stringify({
                    phoneNumber: username,
                    requestCount: requestCount,
                    sessionKey: sessionKey,
                    requestedFor : 'WEB_LOGIN'
                })
            });

        if (!sendOtp.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const sendOtpRespo = await sendOtp.json()
            if (sendOtpRespo.status === "success") {
                setRequestCount(sendOtpRespo?.response?.requestCount)
                setSessionKey(sendOtpRespo?.response?.sessionKey)
                setHasOtpSent(true)
                setIsButtonDisabled(true);
                setTimer(15); // Reset timer
            } else {
                if (sendOtpRespo?.message === "number_not_registered") {
                    toast.warn(`Only registered user can request login OTP.`)
                } else {
                    console.log(sendOtpRespo)
                }
            }
        }
        setIsSendingOtp(false)
    };

    const verifyOtp = async () => {
        setIsLogging(true)
        const verifyOtpResponse = await fetch(BASE_URL_API + "verifyOtp",
            {
                method: "POST",
                body: JSON.stringify({
                    sessionKey: sessionKey,
                    phoneNumber: username,
                    otp: otp,
                    requestedFor : 'WEB_LOGIN'
                })
            });

        if (!verifyOtpResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const verifyOtpRespo = await verifyOtpResponse.json()
            if (verifyOtpRespo.status === "success") {
                authCtx.login(verifyOtpRespo?.authRespo?.token, verifyOtpRespo?.authRespo?.vendorIdentifier, verifyOtpRespo?.authRespo?.clientData)
            } else {
                if (verifyOtpRespo?.response === "failed") {
                    if (verifyOtpRespo?.message === "Otp Expired") {
                        alert("Otp Expired, please send otp again!")
                    } else {
                        alert(verifyOtpRespo?.message)
                    }
                }
            }
            console.log(verifyOtpRespo)
        }
        setIsLogging(false)
    }

    const auth = async () => {
        setIsLogging(true)
        const authResponse = await fetch(BASE_URL_API + "auth",
            {
                method: "POST",
                body: JSON.stringify({
                    username: username,
                    password: password
                })
            });

        if (!authResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const authRespo = await authResponse.json()
            if (authRespo.status === "success") {
                authCtx.login(authRespo?.authRespo?.authToken, authRespo?.authRespo?.vendorIdentifier, authRespo?.authRespo?.clientData)
            } else {
                toast.error(`Invalid login credentials, please enter correct username & password!`)
            }
            console.log(authRespo)
        }
        setIsLogging(false)
    }

    const loginHandler = async () => {
        if (hasOtpSent) {
            verifyOtp()
            console.log(sessionKey, username, otp)
        } else {
            auth()
            console.log(username, password)
        }
        // authCtx.login("token", "useridentifier")


    }

    useEffect(() => {
        const metaThemeColor = document.querySelector("meta[name='theme-color']");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", "#EAF9FF");
        } else {
            const meta = document.createElement("meta");
            meta.name = "theme-color";
            meta.content = "#EAF9FF";
            document.head.appendChild(meta);
        }
    }, []);
   
    useEffect(() => {
        if(otp?.length===4) {
            loginHandler()
        }
    }, [otp])
    


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <ToastContainer />

                <div className={styles.mainContent}>
                    <div className={styles.firstPaintWrapper}>
                        <div className={styles.fpHeaderWrapper}>
                            <div className={styles.fpHeaderContainer}>
                                <div className={styles.fpHeaderLeft}>

                                </div>
                                <div className={styles.fpHeaderRight}>
                                    <div className={styles.changeLangWrapper}>
                                        <select className={styles.changeLanguageOption}
                                            onChange={(e) => changeLanguage(e.target.value)}
                                            defaultValue={currentLanguage}
                                        >
                                            <option value={`en`} >English - En</option>
                                            <option value={`hinglish`} >Hinglish - hi</option>
                                            <option value={`hi`} >Hindi - hi</option>
                                            <option value={`tm`} >Tamil - tm</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.fpBodyWrapper}>
                            <div className={styles.leftWrapper}>
                                <div className={styles.messageWrapper}>
                                    <div className={styles.stylelink}>
                                        <h1>{translations?.stylelink}</h1>
                                    </div>
                                    <div className={styles.numberOneHiringPlatform}>
                                        <h2>{translations?.numberOneHiringPlatform}</h2>
                                    </div>
                                    <div className={styles.uspWrapper}>
                                        <div className={styles.eachUspWrapper} >90+ {translations?.cities}</div>
                                        <div className={styles.eachUspWrapper} >2400+ {translations?.salons}</div>
                                        <div className={styles.eachUspWrapper} >{translations?.hiringWithin48Hours}</div>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.rightWrapper}>
                                <div className={styles.rightContainer}>
                                    <div className={styles.loginWrapper}>
                                        <div className={styles.loginContainer}>

                                            {hasUsernameValidated === false ?
                                                <div className={styles.fieldsWrapper}>
                                                    <div className={styles.title}>
                                                        {translations?.signingLine}
                                                    </div>
                                                    <div className={styles.usernameWrapper}>
                                                        <input
                                                            autoFocus
                                                            placeholder={translations?.pleaseEnterUsername}
                                                            onChange={(e) => setUsername(e.target.value)}
                                                            value={username}
                                                        />
                                                    </div>
                                                    <div className={styles.nextBtnWrapper}>
                                                        <button onClick={() => validateUsernameHandler()} >{translations?.next}</button>
                                                    </div>
                                                </div>
                                                :
                                                <div className={styles.fieldsWrapper}>
                                                    <div className={styles.title}>
                                                        {translations?.signingLine}
                                                    </div>
                                                    <div className={styles.usernameWrapperValidated}
                                                        onClick={() => changeUsernameHandler()}
                                                    >
                                                        <div className={styles.username}>
                                                            {username}
                                                        </div>
                                                        <div>
                                                            <button onClick={() => changeUsernameHandler()}>
                                                                {translations?.change}
                                                            </button>
                                                        </div>
                                                    </div>

                                                    {!hasOtpSent &&
                                                        <div className={styles.usernameWrapper}>
                                                            <input
                                                                autoFocus
                                                                placeholder={translations?.pleaseEnterPassword}
                                                                onChange={(e) => setPassword(e.target.value)}
                                                                value={password}
                                                                type={isPasswordVisible === false ? `password` : `text`}
                                                            />
                                                            <div className={styles.passwordEye} onClick={() => setIsPasswordVisible(!isPasswordVisible)} >
                                                                {isPasswordVisible === false ?
                                                                    <FaEyeSlash color="#c7c7c7" />
                                                                    :
                                                                    <FaEye color="#c7c7c7" />
                                                                }
                                                            </div>
                                                        </div>
                                                    }


                                                    {hasOtpSent &&
                                                        <div>
                                                            <div className={styles.resendSectionWrapper}>
                                                                <div>
                                                                    {translations?.havenotReceivedOTP}
                                                                </div>
                                                                {isButtonDisabled ?
                                                                    <div>
                                                                        {translations?.resendOtpIn} {timer} {translations?.inSeconds}
                                                                    </div>
                                                                    :
                                                                    isSendingOtp === true ?
                                                                        <div>
                                                                            {translations?.sendingOTP}...
                                                                        </div>
                                                                        :
                                                                        <div onClick={() => sendOtpHandler()}>
                                                                            {translations?.resendOtp}
                                                                        </div>
                                                                }
                                                            </div>
                                                            <OtpInput
                                                                shouldAutoFocus={true}
                                                                value={otp}
                                                                onChange={setOtp}
                                                                numInputs={4}
                                                                renderSeparator={<span>&nbsp;-&nbsp;</span>}
                                                                renderInput={(props) => <input {...props} />}
                                                                containerStyle={{ display: 'flex', justifyContent: "center", paddingTop: '10px', paddingBottom: '10px' }}
                                                                inputStyle={{ height: '36px', width: '36px', border: '1px solid #dfdfdf', borderRadius: '5px' }}
                                                                inputType="number"
                                                            />
                                                        </div>
                                                    }

                                                    {canSendOtp &&
                                                        <div className={styles.sendOtpWrapper}>
                                                            {hasOtpSent ?
                                                                <div className={styles.sendOtpTxt} onClick={() => setHasOtpSent(false)}>
                                                                    <span className={styles.horiLine}></span><button className={styles.enterPasswordToggle}>{translations?.orEnterPassword}</button><span className={styles.horiLine}></span>
                                                                </div>
                                                                :
                                                                isSendingOtp === true ?
                                                                    <div className={styles.sendOtpTxt}>
                                                                        <span className={styles.horiLine}></span><button className={styles.sendOtpBtn}>{translations?.sendingOTP}</button><span className={styles.horiLine}></span>
                                                                    </div>
                                                                    :
                                                                    <div className={styles.sendOtpTxt} onClick={() => sendOtpHandler()}>
                                                                        <span className={styles.horiLine}></span><button className={styles.sendOtpBtn}>{translations?.orSendOtp} {username} {translations?.to} </button><span className={styles.horiLine}></span>
                                                                    </div>
                                                            }
                                                        </div>
                                                    }

                                                    <div className={styles.nextBtnWrapper}>
                                                        {isLogging === false ?
                                                            <button onClick={() => loginHandler()} >{translations?.login}</button>
                                                            :
                                                            <button>{translations?.loggingIn}</button>
                                                        }
                                                    </div>
                                                </div>
                                            }


                                            <div className={styles.forgotPasswordWrapper}>
                                                <div>
                                                    <Link to={`/forgot-password`}>{translations?.forgotPassword}</Link>
                                                </div>

                                            </div>
                                        </div>
                                    </div>



                                </div>
                            </div>

                        </div>
                        <div className={styles.fpFooterWrapper}>
                            <div className={styles.fpFooterContainer}>
                                <div>
                                    {translations?.allRightsReserved}
                                </div>
                                <div>
                                    <a href="https://stylelink.in/hire-salon-staff" target="_blank">{translations?.whyYouShouldHireFromStylelink}</a>
                                </div>
                                <div>
                                    <a href="https://stylelink.in/hire-salon-staff/pricing" target="_blank">{translations?.pricing}</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login