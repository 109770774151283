import React, { useContext, useEffect, useState } from 'react'
import { FaFileSignature } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import LeftNavigator from '../../Components/Headers/LeftNavigator'
import MainHeader from '../../Components/Headers/MainHeader'
import AuthSignAgreement from '../../Components/Services/AuthSignAgreement'
import PreviewAgreement from '../../Components/Services/PreviewAgreement'
import { BASE_URL_API } from '../../References/URLs/api'
import AuthContext from '../../Store/auth-context'
import { useTranslation } from '../../Store/TranslationContext'
import styles from './EachAgreementForSign.module.css'

const EachAgreementForSign = () => {
    const params = useParams()
    const { translations, currentLanguage, changeLanguage } = useTranslation()
    const agreementIdentifier = params?.agreementIdentifier

    const [agreementMode, setAgreementMode] = useState('reading') // signing
    const agreementModeHandler = (k) => setAgreementMode(k)
    const [agreementData, setAgreementData] = useState(null)
    const navigate = useNavigate()

    const authCtx = useContext(AuthContext)
    // console.log(authCtx?.userIdentifier)

    const getAgreementForReview = async () => {
        const getAgreementForReviewResponse = await fetch(BASE_URL_API + "getAgreementForReview",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    agreementIdentifier: agreementIdentifier,
                    userIdentifier: authCtx?.userIdentifier
                })
            });

        if (!getAgreementForReviewResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAgreementForReviewRespo = await getAgreementForReviewResponse.json()
            if (getAgreementForReviewRespo.status === "success") {
                setAgreementData(getAgreementForReviewRespo.response)
            } else {
                if (getAgreementForReviewRespo.responseCode === 302) {
                    authCtx.logout()
                }

            }
            console.log(getAgreementForReviewRespo)
        }
    }

    useEffect(() => {
        getAgreementForReview()
    }, [agreementIdentifier])


    return (
        <div className={styles.mainWrapper}>
            <MainHeader />
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <LeftNavigator />
                    <div className={styles.rightContentWrapper}>
                        <div className={styles.agreementMainWrapper}>
                            <div className={styles.agreementMainContainer}>
                                <div className={styles.agreementMainContent}>

                                    <div className={styles.agreementLeftWrapper}>
                                        {/* {params?.agreementIdentifier} */}

                                        <PreviewAgreement
                                            agreementIdentifier={agreementIdentifier}
                                            agreementMode={agreementMode}
                                            agreementModeHandler={agreementModeHandler}
                                        />
                                    </div>

                                    <div className={styles.agreementRightWrapper}>
                                        <div className={styles.agreementRightContainer}>
                                            {agreementData?.agreementSigned === "1" ?
                                                <div className={styles.mainBodyWrapperSigned}>
                                                    <div className={styles.iconWrapper}>
                                                        <FaFileSignature size={60} color="#da1884" />
                                                    </div>
                                                    <div>
                                                        {translations?.haveAlreadySignedTheAgreement}
                                                    </div>

                                                    <div className={styles.actionWrapperSigned}>
                                                        <Link to={`/services-agreement`} className={styles.cancelOtp}>{translations?.okay}</Link>
                                                    </div>
                                                </div>
                                                :
                                                <AuthSignAgreement
                                                    agreementIdentifier={agreementIdentifier}
                                                    agreementMode={agreementMode}
                                                    agreementModeHandler={agreementModeHandler}
                                                    agreementData={agreementData}
                                                />
                                            }
                                        </div>
                                    </div>


                                </div>




                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EachAgreementForSign