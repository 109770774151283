import logo from './logo.svg';
import './App.css';
import MainNavigator from './Navigator/MainNavigator';
import { TranslationProvider } from './Store/TranslationContext';


function App() {
  return (
    <TranslationProvider>
      <MainNavigator />
    </TranslationProvider>
  );
}

export default App;
