import React, { useContext, useEffect, useState } from 'react'
import LeftNavigator from '../../Components/Headers/LeftNavigator'
import MainHeader from '../../Components/Headers/MainHeader'
import styles from './AgreementHome.module.css'

import { FaFolderOpen } from "react-icons/fa";
import { Link } from 'react-router-dom';
import AuthContext from '../../Store/auth-context';
import { BASE_URL_API } from '../../References/URLs/api';
import { useTranslation } from '../../Store/TranslationContext';


const AgreementHome = () => {
    const authCtx = useContext(AuthContext)
    const { translations, currentLanguage, changeLanguage } = useTranslation()

    const [haveSubscribed, setHaveSubscribed] = useState(true)
    const [haveSignedTheAgreement, setHaveSignedTheAgreement] = useState(false)
    const [allAgreements, setAllAgreements] = useState([])
    const [loadingAgreements, setLoadingAgreements] = useState(false)

    const getAllAgreements = async () => {
        setLoadingAgreements(true)
        const getAllAgreementsResponse = await fetch(BASE_URL_API + "getAllAgreements",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    userIdentifier: authCtx?.userIdentifier
                })
            });


        if (!getAllAgreementsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllAgreementsRespo = await getAllAgreementsResponse.json()
            if (getAllAgreementsRespo.status === "success") {
                setAllAgreements(getAllAgreementsRespo.response)
            } else {
                if(getAllAgreementsRespo.responseCode===302) {
                    authCtx.logout()
                }

                console.log(getAllAgreementsRespo)
            }
        }
        setLoadingAgreements(false)
    }


    useEffect(() => {
        getAllAgreements()
    }, [])




    return (
        <div className={styles.mainWrapper}>
            <MainHeader />
            
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <LeftNavigator />
                    <div className={styles.rightContentWrapper}>
                        {haveSubscribed === false ?
                            <div className={styles.notSubscribedForAnyPlanWrapper}>
                                <div className={styles.notSubscribedForAnyPlanContainer}>
                                    <div>
                                        <FaFolderOpen size={200} color="#212124" />
                                    </div>
                                    <div className={styles.nsfapTxt}>
                                        <h2>
                                            You haven't subscribed for any plan.
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            :
                            haveSubscribed === true &&
                                haveSignedTheAgreement === false ?
                                <div>
                                    {loadingAgreements === false ?
                                        <div className={styles.titleWrapper}>
                                            {translations?.allAgreementsWillAppearHere}
                                        </div>
                                        :
                                        <div className={styles.titleWrapper}>
                                            {translations?.loadingAgreements}
                                        </div>
                                    }

                                    <div className={styles.allAgreementsWrapper}>
                                        <div className={styles.agreementSearchFilterWrapper}>
                                            <div className={styles.searchWrapper}>
                                                <input placeholder="Search here..." />
                                            </div>
                                            <div className={styles.statusWrapper}>
                                                <select>
                                                    <option>{translations?.selectStatus}</option>
                                                    <option>{translations?.signed}</option>
                                                    <option>{translations?.notSigned}</option>
                                                </select>
                                            </div>
                                        </div>
                                        {allAgreements?.length > 0 ?
                                            <div className={styles.agreementListWrapper}>
                                                {allAgreements?.map((item, index) => (
                                                    <div key={index} className={styles.eachAgreementRow}>
                                                        <div className={styles.selectedPlan}>
                                                            <div className={styles.selectedPlanTxt}>
                                                                {item?.selectedPlan}
                                                            </div>
                                                            <div className={styles.createdOn}>
                                                                {item?.createdOn}
                                                            </div>
                                                        </div>
                                                        <div className={styles.currentStatus}>
                                                            {item?.agreementSigned === "0" ? <div className={styles.notSigned}>{translations?.notSigned}</div> : <div className={styles.signed}>{translations?.signed}</div>}
                                                        </div>
                                                        <div className={styles.actionWrapper}>
                                                            {item?.agreementSigned === "1" ?
                                                                <Link to={`/services-agreement/${item?.agreementIdentifier}`}>{translations?.viewAgreement}</Link>
                                                                :
                                                                <Link to={`/services-agreement/${item?.agreementIdentifier}`}>{translations?.viewSign}</Link>
                                                            }
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            :
                                            <div className={styles.noAgreementAvailable}>
                                                {translations?.noAgreementAvailable}
                                            </div>
                                        }


                                    </div>


                                </div>
                                :
                                <div>
                                    <div>

                                    </div>
                                    <div>
                                        You haven't signed!
                                    </div>
                                </div>
                        }


                    </div>
                </div>
            </div>
        </div>
    )
}

export default AgreementHome