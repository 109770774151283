import React, { useContext, useEffect, useState } from 'react'
import LeftNavigator from '../../Components/Headers/LeftNavigator'
import MainHeader from '../../Components/Headers/MainHeader'
import { humanReadableTimePassed } from '../../Helper/jarvis-jr'
import { BASE_URL_API } from '../../References/URLs/api'
import AuthContext from '../../Store/auth-context'
import styles from './AllLocations.module.css'

const AllLocations = () => {
    const authCtx = useContext(AuthContext)
    const [allStores, setAllStores] = useState([])
    const [activeTab, setActiveTab] = useState('Stores')
    const [filteredData, setFilteredData] = useState([])
    const [searchKey, setSearchKey] = useState('')

    const getAllStores = async () => {
        const getAllStoresResponse = await fetch(BASE_URL_API + "getAllStores",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    vendorIdentifier: authCtx.userIdentifier
                })
            });

        if (!getAllStoresResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllStoresRespo = await getAllStoresResponse.json()
            if (getAllStoresRespo.status === "success") {
                setAllStores(getAllStoresRespo.response)
                setFilteredData(getAllStoresRespo.response)
            } else {
                if (getAllStoresRespo.responseCode === 302) {
                    authCtx.logout()
                } else {
                    setAllStores([])
                    setFilteredData([])
                }
            }
            console.log(getAllStoresRespo)
        }
    }



    useEffect(() => {
        getAllStores()
    }, [])


    const filterData = (data, searchKey) => {
        setFilteredData(data.filter(item =>
            Object.values(item).some(value =>
                value?.toString().toLowerCase().includes(searchKey.toLowerCase())
            )
        ))
    }

    useEffect(() => {
        filterData(allStores, searchKey)
    }, [searchKey])
    

    return (
        <div className={styles.mainWrapper}>
            <MainHeader />
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <LeftNavigator />
                    <div className={styles.rightContentWrapper}>
                        <div className={styles.storesDataWrapper}>
                            <div className={styles.storesDataContainer}>
                                <div className={styles.toggleBtnWrapper}>
                                    <div className={activeTab === "Stores" ? styles.toggleBtnActive : styles.toggleBtn} onClick={() => setActiveTab("Stores")} >Stores</div>
                                    <div className={activeTab === "Locations" ? styles.toggleBtnActive : styles.toggleBtn} onClick={() => setActiveTab("Locations")} >Locations</div>
                                </div>
                                <div className={styles.searchBarWrapper}>
                                    <input placeholder="Search for anything... " value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
                                </div>

                                <div className={styles.sdcBodyWrapper}>
                                    {filteredData?.map((item, index) => (
                                        <div key={index} className={styles.eachStoreWrapper} >
                                            <div className={styles.storeIdentifierWrapper}>
                                                <div className={styles.storeIdentifier}>
                                                    Store ID : {item?.storeIdentifier}
                                                </div>
                                            </div>
                                            <div className={styles.storeName}>
                                                {item?.storeName}
                                            </div>
                                            <div className={styles.storeAddress}>
                                                {item?.storeAddress}
                                            </div>
                                            <div className={styles.createdOn}>
                                                Active since {humanReadableTimePassed(item?.createdOn)}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllLocations