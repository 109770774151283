import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { BASE_URL_API } from '../../References/URLs/api';
import AuthContext from '../../Store/auth-context';
import styles from './NewRequirementPopUp.module.css'

const NewRequirementPopUp = (props) => {
    const { isOpen, onClose } = props
    const authCtx = useContext(AuthContext)
    const navigate = useNavigate()
    console.log(authCtx)
    const [isCreating, setIsCreating] = useState(false)

    useEffect(() => {
        const metaThemeColor = document.querySelector("meta[name='theme-color']");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", "#00000080");
        } else {
            const meta = document.createElement("meta");
            meta.name = "theme-color";
            meta.content = "#00000080";
            document.head.appendChild(meta);
        }
    }, []);

    const createANewRequirement = async () => {
        setIsCreating(true)
        const createANewRequirementResponse = await fetch(BASE_URL_API + "createANewRequirement",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    vendorIdentifier: authCtx?.userIdentifier
                })
            });

        if (!createANewRequirementResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const createANewRequirementRespo = await createANewRequirementResponse.json()
            if (createANewRequirementRespo.status === "success") {
                navigate(`/create-new-requirement/${createANewRequirementRespo?.response}`)
            } else {
                if (createANewRequirementRespo.responseCode === 302) {
                    authCtx.logout()
                }
            }
        }
        setIsCreating(false)
    }



    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.mainBody}>
                        <div className={styles.doYouWantTxt}>
                            Do you want to create a new requirement?
                        </div>
                        <div className={styles.createNowBtnWrapper}>
                            {isCreating === true ?
                                <button>Creating Now...</button>
                                :
                                <button onClick={() => createANewRequirement()}>Create Now</button>
                            }
                        </div>
                        <div className={styles.footer}>
                            <button onClick={() => onClose()}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewRequirementPopUp