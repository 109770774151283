import React, { useContext, useEffect, useState } from 'react'
import LeftNavigator from '../../Components/Headers/LeftNavigator'
import MainHeader from '../../Components/Headers/MainHeader'
import { BASE_URL_API } from '../../References/URLs/api'
import AuthContext from '../../Store/auth-context'
import styles from './AllOpenings.module.css'

import { GiGolfFlag } from "react-icons/gi";
import { IoCodeWorking } from "react-icons/io5";
import { MdAssignmentTurnedIn } from "react-icons/md";
import { humanReadableTimePassed } from '../../Helper/jarvis-jr'






const AllOpenings = () => {
    const authCtx = useContext(AuthContext)
    const [allResumeData, setAllResumeData] = useState([])

    const [filteredData, setFilteredData] = useState([])
    const [searchKey, setSearchKey] = useState('')
    const [status, setStatus] = useState('')

    const getAllOpenings = async () => {
        const getAllOpeningsResponse = await fetch(BASE_URL_API + "getAllOpenings",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    vendorIdentifier: authCtx.userIdentifier
                })
            });

        if (!getAllOpeningsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllOpeningsRespo = await getAllOpeningsResponse.json()
            if (getAllOpeningsRespo.status === "success") {
                setAllResumeData(getAllOpeningsRespo.response)
                setFilteredData(getAllOpeningsRespo.response)
            } else {
                if (getAllOpeningsRespo.responseCode === 302) {
                    authCtx.logout()
                }
            }
            console.log(getAllOpeningsRespo)
        }
    }


    useEffect(() => {
        getAllOpenings()
    }, [])


    const filterData = (data, searchKey, status) => {
        setFilteredData(
            data.filter(item => {
                const matchesSearchKey = Object.values(item).some(value =>
                    value?.toString().toLowerCase().includes(searchKey.toLowerCase())
                );
                const matchesStatus = status ? item?.currentStatus === status : true;
                return matchesSearchKey && matchesStatus;
            })
        );
    }
    
    useEffect(() => {
        filterData(allResumeData, searchKey, status);
    }, [searchKey, status]);
    
    



    return (
        <div className={styles.mainWrapper}>
            <MainHeader />
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <LeftNavigator />
                    <div className={styles.rightContentWrapper}>
                        <div className={styles.resumeTableWrapper}>
                            <div className={styles.filtersWrapper}>
                                <div className={styles.leftFiltersWrapper}>
                                    <input placeholder="Search for anything..." 
                                        onChange={(e) => setSearchKey(e.target.value)}
                                        value={searchKey}
                                    />
                                </div>
                                <div className={styles.dropDownWrapper}>
                                    <select onChange={(e) => setStatus(e.target.value)}>
                                        <option value="">All</option>
                                        <option value="CANDIDATE_HIRED">Candidate Hired</option>
                                        <option value="JOB_ALLOTED">Executive Assigned</option>
                                        <option value="WORKING">Working</option>
                                    </select>
                                </div>
                            </div>
                            <div className={styles.headerWrapper}>
                                {filteredData?.length} / {allResumeData?.length} job(s) available
                            </div>
                            <div className={styles.tableBodyWrapper}>
                                {filteredData?.map((item, index) => (
                                    <div key={index} className={styles.eachJobWrapper}>
                                        <div className={styles.eachJobUpperOne}>
                                            <div className={styles.leftWrapper}>
                                                <div className={styles.jobTitleWrapper}>
                                                    <div className={styles.jobTitle}>{item?.jobTitle}</div>
                                                    <div className={styles.jobProfile} >{item?.jobProfile}</div>
                                                </div>
                                                <div className={styles.storeDetailsWrapper}>
                                                    <div className={styles.storeName} >
                                                        {item?.storeName}
                                                    </div>
                                                    <div className={styles.address}>
                                                        {item?.address}
                                                    </div>
                                                </div>

                                            </div>
                                            <div>
                                                {item?.currentStatus === "JOB_ALLOTED" &&
                                                    <div className={styles.currentStatusTagRequestAccepted}
                                                        title="Means that our executive has reviewed the request and approved that the requirement fall under the subscribed plan."
                                                    >
                                                        Request Accepted&nbsp;<MdAssignmentTurnedIn color={'#E68369'} size={24} />
                                                    </div>
                                                }
                                                {item?.currentStatus === "WORKING" &&
                                                    <div className={styles.currentStatusTagWorking} title="Our executives are hunting for the right candidate." >
                                                        Working&nbsp;<IoCodeWorking color={'#ffcb08'} size={24} />
                                                    </div>
                                                }
                                                {item?.currentStatus === "CANDIDATE_HIRED" &&
                                                    <div className={styles.currentStatusTagHired}
                                                        title="We have placed some candidate for this role."
                                                    >
                                                        Candidate Hired
                                                        <GiGolfFlag color={'green'} size={24} />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className={styles.tagsWrapper}>
                                            <div className={styles.salaryRangeWrapper}>
                                                <div>
                                                    ₹{item?.salaryMin} - ₹{item?.salaryMax}
                                                </div>
                                            </div>
                                            <div className={styles.fnaWrapper}>
                                                {item?.fna}
                                            </div>
                                            <div className={styles.experienceWrapper}>
                                                {item?.experience}
                                            </div>
                                        </div>
                                        <div>
                                            <div className={styles.createdOn}>
                                                Posted {humanReadableTimePassed(item?.creationDate)}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className={styles.thatsAll}>
                                    {filteredData?.length===0 && <p>No records found! Try removing some filters!</p>}
                                    That's all!
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllOpenings