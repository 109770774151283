export const isValidPhoneNumber = (phoneNumber) => {
    // Remove all whitespace from the string
    phoneNumber = phoneNumber.replace(/\s+/g, '');

    // Check if the input is exactly 10 digits long after trimming
    if (!/^\d{10}$/.test(phoneNumber)) {
        return false;
    }

    // Check if the first digit is greater than 4
    if (phoneNumber[0] <= '4') {
        return false;
    }

    // Check if all the digits are the same
    if (/^(\d)\1{9}$/.test(phoneNumber)) {
        return false;
    }

    return true;
}

export const convertToArray = (p) => {
    if (Array.isArray(p)) return p;

    if (typeof p === 'string') {
        try {
            const parsed = JSON.parse(p);
            return Array.isArray(parsed) ? parsed : [parsed];
        } catch (e) {
            return p !== "" ? [p] : [];
        }
    }

    return (p !== undefined && p !== null) ? [p] : [];
};


export const formatDate = (dateStr) => {
    // Helper function to get the ordinal suffix
    function getOrdinalSuffix(day) {
        if (day > 3 && day < 21) return 'th'; // Handle teens (11th, 12th, 13th)
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    }

    // List of month names
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    // Validate input
    if (!dateStr || typeof dateStr !== 'string' || !/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
        return "Invalid date format";
    }

    // Parse the date string
    const [year, month, day] = dateStr.split('-').map(Number);

    // Validate parsed date parts
    if (!year || year < 1 || !month || month < 1 || month > 12 || !day || day < 1 || day > 31) {
        return "Invalid date format";
    }

    // Create a Date object and check if it's valid
    const date = new Date(year, month - 1, day);
    if (isNaN(date.getTime()) || date.getDate() !== day) {
        return "Invalid date format";
    }

    // Format the date
    const dayWithSuffix = day + getOrdinalSuffix(day);
    const monthName = monthNames[month - 1];

    return `${dayWithSuffix} of ${monthName} ${year}`;
}



export const humanReadableTimePassed = (k) => {
    let curTime = new Date();
    curTime = curTime.toISOString();
    try {
        let recTime = new Date(k).toISOString();
        let diff = new Date(curTime) - new Date(recTime);

        let days = Math.floor(diff / (24 * 60 * 60 * 1000));
        let hours = Math.floor((diff - days * (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));

        // let minutes = Math.floor(diff - ((diff - days * (24 * 60 * 60 * 1000)) / ( 60*1000)) );

        let minutes = Math.floor((diff / (1000 * 60)) % 60)


        // console.log("Minutes : ",minutes);

        let returningItem = '';
        if (days < 1) {
            if (hours < 1) {
                returningItem = minutes + " mins ago";
            } else if (hours < 5) {
                returningItem = hours + " hours " + minutes + " mins ago";
            } else if (hours < 12) {
                returningItem = hours + " hours ago";
            } else if (hours < 14) {
                returningItem = " today";
            } else {
                returningItem = " yesterday";
            }
        } else if (days < 30) {
            if (days < 7) {
                returningItem = days + " days ago";
            } else if (days > 6) {
                returningItem = " around a week ago";
            } else if (days < 13) {
                returningItem = days + " days ago";
            } else if (days < 15) {
                returningItem = " 2 weeks ago";
            } else if (days > 15) {
                returningItem = " 3 weeks ago";
            } else {
                returningItem = " around a month ago";
            }
        } else if (days > 29) {
            if (days < 45) {
                returningItem = " around a month ago";
            } else if (days < 60) {
                returningItem = " one & half month ago";
            } else if (days < 75) {
                returningItem = " 2 months ago";
            } else if (days < 90) {
                returningItem = " around 3 months ago";
            } else if (days < 115) {
                returningItem = " 3 months ago";
            } else if (days < 135) {
                returningItem = " 4 months ago";
            } else if (days < 165) {
                returningItem = " 5 months ago";
            } else if (days < 195) {
                returningItem = " 6 months ago";
            } else if (days < 225) {
                returningItem = " 7 months ago";
            } else if (days < 255) {
                returningItem = " 8 months ago";
            } else if (days < 285) {
                returningItem = " 9 months ago";
            } else if (days < 315) {
                returningItem = " 10 months ago";
            } else if (days < 345) {
                returningItem = " 11 months ago";
            } else if (days < 361) {
                returningItem = " last year";
            }
        } else if (days > 360) {
            returningItem = " last year";
        } else if (days > 720) {
            returningItem = " 1 and half year ago";
        } else {
            returningItem = " 2 years ago";
        }

        return returningItem;
    } catch (error) {
        // console.log(error)
        return "InvalidTime"
    }
}
