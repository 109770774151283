import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../../Store/auth-context'
import { useTranslation } from '../../Store/TranslationContext'
import styles from './MainHeader.module.css'

import { RxHamburgerMenu } from "react-icons/rx";
import { Link } from 'react-router-dom';

import { MdLogout } from "react-icons/md";
import { BASE_URL_API } from '../../References/URLs/api';



const MainHeader = () => {
    const { translations, currentLanguage, changeLanguage } = useTranslation()
    const authCtx = useContext(AuthContext)
    // const clientData = JSON.parse(authCtx?.clientData || '{}')
    const clientData = (authCtx?.clientData && JSON.parse(authCtx.clientData)) || {};
    const companyName = clientData?.companyName
    const address = clientData?.address
    const tag = clientData?.selectedPlan

    const getTagClass = () => {
        switch (tag) {
            case 'Plus':
                return styles.tagPlus
            case 'Essential':
                return styles.tagEssential
            case 'Business':
                return styles.tagBusiness
            default:
                return ''
        }
    }

    console.log(tag)





    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.brandNameWrapper}>
                    <div className={styles.brandName}>Greetings, {companyName}</div>
                    {tag !== null &&
                        <div className={styles.tagWrapper}>
                            <div className={`${styles.tag} ${getTagClass()}`}>
                                {tag}
                            </div>
                        </div>
                    }
                </div>
                <div className={styles.logout}>
                    <button onClick={() => authCtx.logout()}><MdLogout />&nbsp;{translations?.logout}</button>
                </div>
            </div>


            <div className={styles.mainContainerMobile}>
                <div className={styles.messageWrapper}>
                    <div className={styles.brandName}>
                        Greetings,
                        <div className={styles.companyName}>
                            &nbsp;{companyName}
                        </div>
                    </div>
                    {tag !== null &&
                        <div className={styles.tagWrapper}>
                            <div className={`${styles.tag} ${getTagClass()}`}>
                                {tag}
                            </div>
                        </div>
                    }
                </div>
                <Link to={`/menu`} className={styles.burgeMenu}>
                    <RxHamburgerMenu size={26} />
                </Link>
            </div>

        </div>
    )
}

export default MainHeader