import React, { useContext, useEffect, useState } from 'react'
import { MdOutlineContactPhone } from 'react-icons/md'
import LeftNavigator from '../../Components/Headers/LeftNavigator'
import MainHeader from '../../Components/Headers/MainHeader'
import { BASE_URL_API } from '../../References/URLs/api'
import AuthContext from '../../Store/auth-context'
import styles from './ContactPreferences.module.css'

const ContactPreferences = () => {
    const authCtx = useContext(AuthContext)
    const [contactPreferences, setContactPreferences] = useState(null)

    const getPrimaryContacts = async () => {
        const getPrimaryContactsResponse = await fetch(BASE_URL_API+"getPrimaryContacts",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                vendorIdentifier : authCtx.userIdentifier
            })
        });

        if(!getPrimaryContactsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getPrimaryContactsRespo = await getPrimaryContactsResponse.json()
            if(getPrimaryContactsRespo.status==="success") {
                setContactPreferences(getPrimaryContactsRespo?.response)
            } else {
                if(getPrimaryContactsRespo.responseCode===302) {
                    authCtx.logout()
                } else {
                    setContactPreferences(null)
                }
            }
            console.log(getPrimaryContactsRespo)
        }
    }

    useEffect(() => {
        getPrimaryContacts()
    }, [])
    



    return (
        <div className={styles.mainWrapper}>
            <MainHeader />
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <LeftNavigator />
                    <div className={styles.rightContentWrapper}>
                        <div className={styles.contactWrapper}>
                            <div className={styles.contactHeader}>
                                <MdOutlineContactPhone size={40} color="#da1884" />
                                <h4>Contact Preferences </h4>
                            </div>
                            <div>
                                <div className={styles.eachSectionWrapper}>
                                    <div className={styles.sectionCaption}>
                                        Primary E-mail
                                    </div>
                                    <div className={styles.sectionValue}>
                                        {contactPreferences?.vender_email}
                                    </div>
                                </div>

                                <div className={styles.eachSectionWrapper}>
                                    <div className={styles.sectionCaption}>
                                        Primary Phone
                                    </div>
                                    <div className={styles.sectionValue}>
                                        +91-{contactPreferences?.vender_contact}
                                    </div>
                                </div>

                                <div className={styles.eachSectionWrapper}>
                                    <div className={styles.sectionCaption}>
                                        WhatsApp Number
                                    </div>
                                    <div className={styles.sectionValue}>
                                        +91-{contactPreferences?.whatsapp_no}
                                    </div>
                                </div>

                                <div className={styles.noteWrapper}>
                                    <div>
                                        The above contact details are for official communication purposes, you can manage your HR / Interviewers at Locations Page.
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactPreferences