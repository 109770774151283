import React, { useContext, useEffect, useState } from 'react'
import OTPInput from 'react-otp-input'
import { Link, useNavigate } from 'react-router-dom'
import styles from './AuthSignAgreement.module.css'
import { FaFileSignature } from "react-icons/fa";
import { BASE_URL_API } from '../../References/URLs/api';
import AuthContext from '../../Store/auth-context';
import { useTranslation } from '../../Store/TranslationContext';


const AuthSignAgreement = (props) => {
    const { agreementIdentifier, agreementMode, agreementModeHandler, agreementData } = props
    const { translations, currentLanguage, changeLanguage } = useTranslation()
    const [otp, setOtp] = useState('');
    const authCtx = useContext(AuthContext)
    const navigate = useNavigate()

    const [currentStep, setCurrentStep] = useState(1)
    const [isSendingOtp, setIsSendingOtp] = useState(false)
    const [isVerifyingOtp, setIsVerifyingOtp] = useState(false)
    const [sessionKey, setSessionKey] = useState('')
    const [phoneNumber, setphoneNumber] = useState('')
    const [requestCount, setRequestCount] = useState(0)
    const [timer, setTimer] = useState(25)
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [authSignatoryName, setAuthSignatoryName] = useState('')
    const [isSigningAgreement, setIsSigningAgreement] = useState(false)

    const sendOTPHandler = () => {
        sendOtpForAgreementSigning()
    }

    const sendOtpForAgreementSigning = async () => {
        setIsSendingOtp(true)
        const sendOtpForAgreementSigningResponse = await fetch(BASE_URL_API + "sendOtpForAgreementSigning",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    vendorIdentifier: authCtx?.userIdentifier,
                    requestCount: requestCount,
                    sessionKey: sessionKey
                })
            });

        if (!sendOtpForAgreementSigningResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const sendOtpForAgreementSigningRespo = await sendOtpForAgreementSigningResponse.json()
            if (sendOtpForAgreementSigningRespo.status === "success") {
                setCurrentStep(2)
                setRequestCount(sendOtpForAgreementSigningRespo?.response?.requestCount)
                setSessionKey(sendOtpForAgreementSigningRespo?.response?.sessionKey)
                setphoneNumber(sendOtpForAgreementSigningRespo?.response?.phoneNumber)
                setIsButtonDisabled(true);
                setTimer(25); // Reset timer
            } else {
                if (sendOtpForAgreementSigningRespo?.responseCode === 302) {
                    authCtx.logout()
                }
            }
            console.log(sendOtpForAgreementSigningRespo)
        }
        setIsSendingOtp(false)
    }

    const verifyOTPHandler = () => {
        verifyOtpForAgreementSigning()
    }

    const verifyOtpForAgreementSigning = async () => {
        setIsVerifyingOtp(true)
        const verifyOtpForAgreementSigningResponse = await fetch(BASE_URL_API + "verifyOtpForAgreementSigning",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx?.token,
                    sessionKey: sessionKey,
                    phoneNumber: phoneNumber,
                    otp: otp
                })
            });

        if (!verifyOtpForAgreementSigningResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const verifyOtpForAgreementSigningRespo = await verifyOtpForAgreementSigningResponse.json()
            if (verifyOtpForAgreementSigningRespo.status === "success") {
                setCurrentStep(3)
            } else {
                if (verifyOtpForAgreementSigningRespo?.response === "failed") {
                    if (verifyOtpForAgreementSigningRespo?.message === "Otp Expired") {
                        alert("Otp Expired, please send otp again!")
                    } else {
                        alert(verifyOtpForAgreementSigningRespo?.message)
                    }
                }
            }
            console.log(verifyOtpForAgreementSigningRespo)
        }
        setIsVerifyingOtp(false)
    }

    const eSignAgreement = async () => {
        setIsSigningAgreement(true)
        const eSignAgreementResponse = await fetch(BASE_URL_API + "eSignAgreement",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    agreementIdentifier: agreementIdentifier,
                    authSignNameEntered: authSignatoryName
                })
            });

        if (!eSignAgreementResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const eSignAgreementRespo = await eSignAgreementResponse.json()
            if (eSignAgreementRespo.status === "success") {
                navigate("/services-agreement")
            } else {
                if (eSignAgreementRespo.responseCode === 302) {
                    authCtx.logout()
                } else {

                }
            }
        }
        setIsSigningAgreement(false)
    }

    useEffect(() => {
        let interval;

        if (isButtonDisabled) {
            interval = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer === 1) {
                        clearInterval(interval);
                        setIsButtonDisabled(false);
                        return 25; // Reset timer for next OTP send
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [isButtonDisabled]);


    useEffect(() => {
        setCurrentStep(1)
        setOtp('')
    }, [agreementMode])

    useEffect(() => {
        if (otp?.length === 4) {
            verifyOTPHandler()
        }
    }, [otp])



    if (agreementMode === 'reading') {
        return (
            <div className={styles.mainWrapperk}>
                <div className={styles.mainContainerk}>
                    <div className={styles.iconWrapper}>
                        <FaFileSignature size={60} color="#da1884" />
                    </div>
                    <div className={styles.pleaseSignTxt}>{translations?.ifYouHaveReviewedItYouCanSignTheAgreement}</div>
                    <div className={styles.startSigningBtn}>
                        <button onClick={() => agreementModeHandler('signing')} >{translations?.signNow}</button>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer}>
                    {agreementData?.agreementSigned === "1" ?
                        <div className={styles.mainBodyWrapperSigned}>
                            <div className={styles.iconWrapper}>
                                <FaFileSignature size={60} color="#da1884" />
                            </div>
                            <div>
                                {translations?.haveAlreadySignedTheAgreement}
                            </div>

                            <div className={styles.actionWrapperSigned}>
                                <button onClick={() => agreementModeHandler('reading')} className={styles.cancelOtp}>{translations?.okay}</button>
                            </div>
                        </div>
                        :
                        <div className={styles.mainBodyWrapper}>
                            <div className={styles.titleWrapper}>{translations?.eSignServiceAgreement}</div>
                            {currentStep === 1 &&
                                <div className={styles.stepWrapper}>
                                    <div className={styles.firstNoteWrapper}>
                                        <div className={styles.thinTxt}>{translations?.needToVerifyYouIdentifyToeSign}</div>
                                        <p>
                                            {translations?.willSendFourDigitOtpOnWhatsApp}
                                        </p>

                                    </div>
                                </div>
                            }
                            {currentStep === 2 &&
                                <div className={styles.stepWrapper}>
                                    <div className={styles.otpSentWrapper}>
                                        <div className={styles.otpSentTitle}>
                                            {translations?.haveSentOtp}
                                        </div>
                                        <div>
                                            <div className={styles.pleaseEnterOtp}>
                                                <p>
                                                    {translations?.enterOtp}
                                                </p>
                                            </div>
                                            <div className={styles.enterOtpWrapper}>
                                                <OTPInput
                                                    value={otp}
                                                    onChange={setOtp}
                                                    numInputs={4}
                                                    renderSeparator={<span>&nbsp;&nbsp;</span>}
                                                    renderInput={(props) => <input {...props} />}
                                                    containerStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                    inputStyle={{ height: '40px', width: '40px', border: '1px solid #dfdfdf', borderRadius: '5px' }}
                                                    inputType="number"
                                                // shouldAutoFocus={true}
                                                />
                                            </div>
                                            <div className={styles.resendOtpWrapper}>
                                                {isButtonDisabled === false ?
                                                    isSendingOtp === true ?
                                                        <button >{translations?.sendingOTP}</button>
                                                        :
                                                        <button onClick={() => sendOTPHandler()} >{translations?.resendOtp}</button>
                                                    :
                                                    <div className={styles.resendOtpTimerTxt}>{translations?.resendOtpIn} {timer} {translations?.inSeconds}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            }
                            {currentStep === 3 &&
                                <div className={styles.stepWrapper}>
                                    <div className={styles.authSignatoryTitle}>
                                        <p>
                                            {translations?.pleaseEnterAuthSignatoryName}
                                        </p>
                                    </div>

                                    <div className={styles.textareaWrapper}>
                                        <textarea placeholder={translations?.pleaseEnterAuthSignatoryName}
                                            rows={3}
                                            onChange={(e) => setAuthSignatoryName(e.target.value)}
                                            value={authSignatoryName}
                                        >
                                        </textarea>
                                    </div>
                                </div>

                            }

                            {currentStep === 1 &&
                                <div className={styles.actionWrapper}>
                                    <button onClick={() => agreementModeHandler('reading')} className={styles.cancelOtp}>{translations?.cancel}</button>
                                    {isSendingOtp === true ?
                                        <button className={styles.sendOtp} >{translations?.sendingOTP}</button>
                                        :
                                        <button onClick={() => sendOTPHandler()} className={styles.sendOtp} >{translations?.sendOtp}</button>
                                    }
                                </div>
                            }
                            {currentStep === 2 &&
                                <div className={styles.actionWrapper}>
                                    <button onClick={() => agreementModeHandler('reading')} className={styles.cancelOtp}>{translations?.cancel}</button>
                                    {isVerifyingOtp === true ?
                                        <button className={styles.sendOtp} >{translations?.verifyingOtp}</button>
                                        :
                                        <button onClick={() => verifyOTPHandler()} className={styles.sendOtp} >{translations?.verifyOtp}!</button>
                                    }
                                </div>
                            }
                            {currentStep === 3 &&
                                <div className={styles.actionWrapper}>
                                    <button onClick={() => agreementModeHandler('reading')} className={styles.cancelOtp}>{translations?.cancel}</button>
                                    {isSigningAgreement === true ?
                                        <button className={styles.sendOtp} >{translations?.pleaseWait}!</button>
                                        :
                                        <button onClick={() => eSignAgreement()} className={styles.sendOtp} >{translations?.signNow}!</button>
                                    }
                                </div>
                            }
                        </div>

                    }
                </div>
            </div>
        )

    }

}

export default AuthSignAgreement