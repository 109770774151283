import React from 'react'
import { FaInfoCircle, FaQuestion } from 'react-icons/fa'
import { MdChatBubble } from 'react-icons/md'
import styles from './SPBusiness.module.css'

const SPBusiness = () => {
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.planTagWrapper}>
                <div className={styles.brandLogo}>
                    <img src="https://stylelink.s3.ap-south-1.amazonaws.com/stylelink-logos/stylelink-small-logo.png" />
                </div>
                <div className={styles.tagWrapper}>
                    <div className={styles.tag}>
                        Business
                    </div>
                </div>
            </div>
            <div className={styles.planTagLine}>
                Most suitable plan for premium salons
            </div>

            <div className={styles.validTxt}>
                Member till 30th April 2025
            </div>

            <div className={styles.planBenefitsWrapper}>
                <div className={styles.planBenefitTitle}>Business Plan Benefits</div>
                <div className={styles.planBenefitsContainer}>
                    <div className={styles.eachPlanBenefitWrapper}>
                        <div className={styles.epfTitle}>
                            90 Days Replacement
                        </div>
                        <div className={styles.epfCaption}>
                            Even if a staff quits at 90th day, we will provide replacement without any additional charges.
                        </div>

                    </div>
                    <div className={styles.eachPlanBenefitWrapper}>
                        <div className={styles.epfTitle}>
                            Hiring @ Just 999/-
                        </div>
                        <div className={styles.epfCaption}>
                            Rathar than paying ₹4500 on every hiring, just pay ₹999
                        </div>
                    </div>
                    <div className={styles.eachPlanBenefitWrapper}>
                        <div className={styles.epfTitle}>
                            Guaranteed Placement
                        </div>
                        <div className={styles.epfCaption}>
                            No more business loss, you get staff recruited within the committed time.
                        </div>
                    </div>
                </div>

                <div className={styles.faqContainer}>
                    <div className={styles.txtTitleWrapper}>
                        <div className={styles.txtIcon}>
                            <FaQuestion size={22} />
                        </div>
                        <div className={styles.txtTitle}>
                            Frequently Asked Questions
                        </div>
                    </div>
                    <div>

                    </div>

                </div>

                <div className={styles.faqContainer}>
                    <div className={styles.txtTitleWrapper}>
                        <div className={styles.txtIcon}>
                            <MdChatBubble size={22} />
                        </div>
                        <div className={styles.txtTitle}>
                            Need Help?
                        </div>
                    </div>
                    <div>Need Help?</div>
                </div>

                <div className={styles.faqContainer}>
                    <div className={styles.txtTitleWrapper}>
                        <div className={styles.txtIcon}>
                            <FaInfoCircle size={22} />
                        </div>
                        <div className={styles.txtTitle}>
                            Terms and Conditions
                        </div>
                    </div>
                    <div>Terms and Conditions</div>
                </div>


            </div>
        </div>
    )
}

export default SPBusiness