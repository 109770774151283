import React, { useState } from 'react'
import LeftNavigator from '../../Components/Headers/LeftNavigator'
import MainHeader from '../../Components/Headers/MainHeader'
import EachResumeRow from '../../Components/LandingPages/EachResumeRow'
import styles from './AllResume.module.css'

const AllResume = () => {

    const viewResumeHandler = async () => {

    }

    const [allResumeData, setAllResumeData] = useState([])


    return (
        <div className={styles.mainWrapper}>
            <MainHeader />
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <LeftNavigator />
                    <div className={styles.rightContentWrapper}>
                        <div className={styles.resumeTableWrapper}>
                            <div className={styles.filtersWrapper}>
                                <div className={styles.leftFiltersWrapper}>
                                    <input placeholder="Search for anything..." />
                                </div>
                                <div>
                                    <button>Filter</button>
                                </div>
                            </div>
                            <div className={styles.headerWrapper}>
                                <div className={styles.sn}>#</div>
                                <div className={styles.candidateName}>Candidate Name</div>
                                <div className={styles.phoneNumber}>Phone Number</div>
                                <div className={styles.profile}>Profile</div>
                                <div className={styles.age}>Age</div>
                                <div className={styles.gender}>Gender</div>
                                <div className={styles.action}>Action</div>
                            </div>
                            <div className={styles.tableBodyWrapper}>
                                {allResumeData?.map((item, index) => (
                                    <EachResumeRow viewResumeHandler={viewResumeHandler} key={index} item={item} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AllResume