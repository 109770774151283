import React from 'react'
import styles from './LoaderFullPage.module.css'

const LoaderFullPage = () => {
    return (
        <div className={styles.mainWrapper}>
            <img src="https://cdn.pixabay.com/animation/2023/05/02/04/29/04-29-06-428_512.gif" />
        </div>
    )
}

export default LoaderFullPage